exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_modal__DDZwJ {\n  -webkit-transform: translateY(0);\n          transform: translateY(0);\n  width: 420px;\n  border-radius: 6px;\n  padding: 20px 40px;\n  background-color: #FFFFFF;\n  overflow-y: auto;\n  -webkit-transition: all 0.2s;\n  transition: all 0.2s;\n  max-height: 90vh; }\n  .styles_modal--big__3kRF_ {\n    width: 500px; }\n    .styles_modal--big__3kRF_ form {\n      max-width: 100% !important; }\n  .styles_modal__backdrop__1dGw8 {\n    position: fixed;\n    z-index: 1000;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(0, 0, 0, 0.5);\n    -webkit-transition: opacity 0.2s ease;\n    transition: opacity 0.2s ease; }\n    .styles_modal__backdrop-enter__3drZH {\n      opacity: 0; }\n      .styles_modal__backdrop-enter__3drZH .styles_modal__DDZwJ {\n        opacity: 0;\n        -webkit-transform: translateY(-30px);\n                transform: translateY(-30px); }\n    .styles_modal__backdrop-enter-active__1yFF8 {\n      opacity: 1; }\n      .styles_modal__backdrop-enter-active__1yFF8 .styles_modal__DDZwJ {\n        opacity: 1;\n        -webkit-transform: translateY(0);\n                transform: translateY(0); }\n    .styles_modal__backdrop-exit__3-3fg {\n      opacity: 1; }\n      .styles_modal__backdrop-exit__3-3fg .styles_modal__DDZwJ {\n        opacity: 1;\n        -webkit-transform: translateY(0);\n                transform: translateY(0); }\n    .styles_modal__backdrop-exit-active__3sKzR {\n      opacity: 0; }\n      .styles_modal__backdrop-exit-active__3sKzR .styles_modal__DDZwJ {\n        opacity: 0;\n        -webkit-transform: translateY(-30px);\n                transform: translateY(-30px); }\n", ""]);

// Exports
exports.locals = {
	"modal": "styles_modal__DDZwJ",
	"modal--big": "styles_modal--big__3kRF_",
	"modal__backdrop": "styles_modal__backdrop__1dGw8",
	"modal__backdrop-enter": "styles_modal__backdrop-enter__3drZH",
	"modal__backdrop-enter-active": "styles_modal__backdrop-enter-active__1yFF8",
	"modal__backdrop-exit": "styles_modal__backdrop-exit__3-3fg",
	"modal__backdrop-exit-active": "styles_modal__backdrop-exit-active__3sKzR"
};