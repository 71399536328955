exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_button__1pBjF {\n  background-color: #FF3E5F;\n  width: 100%;\n  padding: 15px;\n  border: none;\n  border-radius: 6px;\n  cursor: pointer; }\n  .styles_button--gray__1N0YI {\n    background: #F5F6F7; }\n    .styles_button--gray__1N0YI .styles_button__text__Qj9CI {\n      color: #808080; }\n    .styles_button--gray__1N0YI:hover {\n      background-color: #EDEDED !important; }\n  .styles_button--green__1t3r0 {\n    background: #34cf4e; }\n    .styles_button--green__1t3r0:hover {\n      background-color: #2aad40 !important; }\n  .styles_button--smaller__2d2bT {\n    padding: 10px 15px; }\n  .styles_button--white__3NZAJ {\n    background: #FFFFFF;\n    border: 1px solid #EBEBEB;\n    padding: 8px 10px; }\n    .styles_button--white__3NZAJ .styles_button__text__Qj9CI {\n      color: #000000; }\n    .styles_button--white__3NZAJ:hover {\n      background-color: #F5F6F7 !important; }\n  .styles_button__1pBjF:hover {\n    background-color: #DB324F; }\n  .styles_button__1pBjF:focus {\n    outline: none !important; }\n  .styles_button__1pBjF:disabled {\n    background-color: #FF3E5F;\n    opacity: 0.75;\n    cursor: not-allowed; }\n  .styles_button__text__Qj9CI {\n    color: #FFFFFF;\n    font-size: 14px;\n    font-weight: 600;\n    -webkit-user-select: none;\n            user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none; }\n", ""]);

// Exports
exports.locals = {
	"button": "styles_button__1pBjF",
	"button--gray": "styles_button--gray__1N0YI",
	"button__text": "styles_button__text__Qj9CI",
	"button--green": "styles_button--green__1t3r0",
	"button--smaller": "styles_button--smaller__2d2bT",
	"button--white": "styles_button--white__3NZAJ"
};