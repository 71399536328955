import React from 'react';
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
}from "react-google-maps";
import { StandaloneSearchBox } from "react-google-maps/lib/components/places/StandaloneSearchBox";
import Input from "../../../molecules/Input";
import styles from './styles.module.scss';
import locationIcon from '../../../../assets/icons/location.png'

const PlacesWithStandaloneSearchBox = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places&language=en`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}

      this.setState({
        places: [],
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const {geometry, address_components} = places[0];
          this.props.handleChangeLocationMap(geometry.location.lat(),geometry.location.lng(),address_components);
          this.setState({
            places,
          });
        },
      })
    },
  }),
  withScriptjs
)(props =>
  <div className={styles.box} data-standalone-searchbox="">
    <StandaloneSearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      onPlacesChanged={props.onPlacesChanged}
    >
      <div className={styles.box__wrapper}>
        <Input
          label="Address"
          type="text"
          value={props.value}
        />
        <div onClick={props.handleShowMap} className={styles.box__button}>
          <img src={locationIcon} alt="location"/>
        </div>
      </div>
    </StandaloneSearchBox>
  </div>
);

export default PlacesWithStandaloneSearchBox
