exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_preloader-page__1Z25n {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  left: 0;\n  top: 0; }\n  .styles_preloader-page__spinner__3B4Br {\n    border: 8px solid #E6E6E6;\n    border-top: 8px solid #FF3E5F;\n    border-radius: 50%;\n    width: 80px;\n    height: 80px;\n    -webkit-animation: styles_spin__2aWTb 1.2s linear infinite;\n            animation: styles_spin__2aWTb 1.2s linear infinite;\n    z-index: 2; }\n  .styles_preloader-page__background__wvBTM {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    background: #000000;\n    opacity: 0.35; }\n\n@-webkit-keyframes styles_spin__2aWTb {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg); }\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg); } }\n\n@keyframes styles_spin__2aWTb {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg); }\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg); } }\n", ""]);

// Exports
exports.locals = {
	"preloader-page": "styles_preloader-page__1Z25n",
	"preloader-page__spinner": "styles_preloader-page__spinner__3B4Br",
	"spin": "styles_spin__2aWTb",
	"preloader-page__background": "styles_preloader-page__background__wvBTM"
};