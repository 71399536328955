import OutsideClickHandler from 'react-outside-click-handler'
import React, { Component } from 'react'
import types from 'prop-types'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Icon from '../../atoms/Icon'
import crossIcon from '../../../assets/icons/x.svg'
import dropIcon from '../../../assets/icons/drop.svg'
import classNames from 'classnames'
import styles from './styles.module.scss'


@observer
class SimpleDropdown extends Component {

  static propTypes = {
    label: types.string,
    onOpen: types.func,
    value: types.string,
    width: types.number,
    hasClearBtn: types.bool,
    onClear: types.func
  }

  static defaultProps = {
    onSelect: () => {},
    onClear: () => {}
  }

  @observable isOpen = false
  @observable value = 'All'

  constructor(props) {
    super(props)
    this.value = props.value
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.value = nextProps.value
    this.isOpen = nextProps.isOpen
  }

  onOpen = val => {
    this.isOpen = val
    this.props.onOpen(val)
  }

  clearInput = () => {
    this.value = 'All'
    this.props.onClear()
  }

  render() {
    return (
      <div className={classNames(styles['dropdown'],
        this.props.hasClearBtn && styles['dropdown--clear'])}
           style={{width: this.props.width ? `${this.props.width}px` : '' }}
      >
        <label>{this.props.label}</label>
        <OutsideClickHandler onOutsideClick={() => {
          this.onOpen(false)
        }}>
          <div className={styles['dropdown__input']}
               onClick={() => this.onOpen(!this.isOpen)}
          >
            <input
              type='text'
              value={this.value || ''}
              readOnly
            />
            <div className={styles['dropdown__icon']}>
              <Icon
                svg={dropIcon}
              />
            </div>
          </div>
          {this.isOpen &&
          <div className={styles['dropdown__options']}>
            {this.props.children}
          </div>
          }
        </OutsideClickHandler>
        {(this.props.hasClearBtn && this.value && this.value !== 'All') &&
          <div className={styles['dropdown__icon-clear']}
               onClick={this.clearInput}
          >
            <Icon
              svg={crossIcon}
              colorStroke='red'
            />
          </div>
        }
      </div>
    )
  }

}

export default SimpleDropdown
