import axios from './axiosInstance'
import { conditionalGetParam } from '../helpers/utils'

export const usersApi = {

  getUsers : (limit, offset, isInvited = false, search, ordering) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin-app/users/?${conditionalGetParam(search, 'search')}${
          conditionalGetParam(ordering, 'ordering')}is_invited=${isInvited}&limit=${limit}&offset=${offset}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getUser : id => {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin-app/users/${id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  editHelper : (id, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`admin-app/helps/${id}/`, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  editWarker : (id, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`admin-app/work_ads/${id}/`, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  deactivateUser : id => {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin-app/users/${id}/deactivate/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  deleteUser : id => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`admin-app/users/${id}/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  activateUser : id => {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin-app/users/${id}/activate/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getUserRating : (limit, offset, id, search, role, ordering) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin-app/users/${id}/rating/?${conditionalGetParam(search, 'search')}${
          conditionalGetParam(role, 'role')}${
          conditionalGetParam(ordering, 'ordering')}limit=${limit}&offset=${offset}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  deleteUserRating : id => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`admin-app/users/${id}/delete_rate/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  resendInvitation : id => {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin-app/users/${id}/resend_invite/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  inviteUser : (fullName, email) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin-app/users/invite_user/`, {
          full_name: fullName,
          email: email
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}
