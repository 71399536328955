import React, { Component } from 'react'
import types from 'prop-types'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import classNames from 'classnames'
import styles from './styles.module.scss'


@observer
class Tabs extends Component {

  static propTypes = {
    tabNames: types.array,
    activeTab: types.string,
    onTabChange: types.func
  }

  @observable activeTabIndex = 0

  constructor(props) {
    super(props)
    this.activeTabIndex = this.props.tabNames.indexOf(this.props.activeTab)
  }

  changeTab = (item, index) => {
    this.activeTabIndex = index
    this.props.onTabChange(item)
  }

  render() {
    return (
      <div className={styles['tabs__wrapper']}>
        <div className={styles['tabs']}>
          {this.props.tabNames.map((item, index) => {
            return (
              <div className={styles['tabs__tab']}
                   key={index}
                   onClick={() => this.changeTab(item, index)}
              >
                <h3 className={styles['tabs__text']}>
                  {item}
                </h3>
                {index === this.activeTabIndex &&
                  <div className={styles['tabs__active']}/>
                }
              </div>
            )
          })}
        </div>
        {this.props.children.map((child, index) => {
          return (
            <div key={index}
                 className={classNames(styles['tabs__content'],
                 index === this.activeTabIndex && styles['tabs__content-active'])}>
              {child}
            </div>
          )
        })}
      </div>
    )
  }

}

export default Tabs
