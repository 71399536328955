import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import types from 'prop-types'
import { computed, observable } from 'mobx'
import { usersApi } from '../../../api'
import moment from 'moment'
import { toast } from 'react-toastify'
import Table from '../../organisms/Table'
import Pagination from '../../organisms/Pagination'
import SearchInput from '../../molecules/SearchInput'
import DropdownSelect from '../../molecules/DropdownSelect'
import { addQueryParam, getQueryParam } from '../../../helpers/history'
import styles from './styles.module.scss'


@inject('modalsStore')
@observer
class UserRating extends Component {

  static propTypes = {
    userId: types.oneOfType([types.string, types.number]),
    onRatingDelete: types.func
  }

  @observable ratingData = []

  @observable search = ''
  @observable role = 'All'
  @observable limit = 10
  @observable total = 0
  @observable page = 0
  @observable isFetching = false
  @observable ordering = ''

  constructor(props) {
    super(props)
    this.page = Number.parseInt(getQueryParam('urP', 1))
    this.limit = getQueryParam('urL', 10)
    this.search = getQueryParam('urS', '')
    this.role = getQueryParam('urR', 'All')
    this.ordering = getQueryParam('urO', '')
    this.loadData()
  }

  loadData = () => {
    this.isFetching = true
    usersApi.getUserRating(this.limit, this.offset, this.props.userId,
      this.search, this.role !== 'All' ? this.role : undefined, this.ordering)
      .then(response => {
        const readyData = []
        response.results.forEach((item) => {
          const entity = {}
          entity.data = []
          entity.id = item.id
          entity.data.push(moment(item.date_created).format('DD.MM.YYYY'))
          entity.data.push(item.ad_title)
          entity.data.push(item.author)
          entity.data.push(item.role)
          entity.data.push(item.rate)
          entity.data.push(item.comment)
          entity.data.push('')
          readyData.push(entity)
        })
        if (!readyData.length) {
          if (this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.ratingData = readyData
          }
        } else {
          this.ratingData = readyData
        }
      })
      .catch(error => {
        toast.error('Error when trying to get user reviews')
      })
      .finally(() => {
        this.isFetching = false
      })
  }

  changeSearch = search => {
    this.search = search
    addQueryParam('urS', search)
    this.loadData()
  }

  changePage = page => {
    this.page = page
    addQueryParam('urP', page)
    this.loadData()
  }

  changeLimit = limit => {
    this.limit = limit.value
    this.page = 1
    addQueryParam('urL', limit.value)
    addQueryParam('urP', 1)
    this.loadData()
  }

  changeCategory = role => {
    this.role = role.value
    this.page = 1
    addQueryParam('urR', role.value)
    addQueryParam('urP', 1)
    this.loadData()
  }

  changeOrder = (param) => {
    this.ordering = param
    addQueryParam('urO', this.ordering)
    this.loadData()
  }

  deleteRating = id => {
    this.props.modalsStore.openConfirmModal(
      'Deletion',
      'Are you sure you want to delete this user review?',
      () => {
        usersApi.deleteUserRating(id)
          .then(response => {
            this.props.onRatingDelete()
            this.isFetching = true
            setTimeout(() => {
              this.loadData()
            }, 300)
            toast.success('Review was successfully deleted')
          })
          .catch(error => {
            toast.error('Error when trying to remove user review')
          })
      }
    )
  }

  @computed get offset() {
    return (this.page - 1) * this.limit
  }

  @computed get totalPages() {
    return Math.ceil(this.total / this.limit)
  }

  render() {
    return (
      <div className={styles['user-rating']}>
        <div className={styles['user-rating__filters']}>
          <SearchInput
            placeholder='Search by Ad Title'
            value={this.search}
            onChange={this.changeSearch}
          />
          <DropdownSelect
            label="by user role"
            value={this.role}
            options={[{
              label: 'All',
              value: 'All'
            }, {
              label: 'Helper',
              value: 'Helper'
            }, {
              label: 'Employer',
              value: 'Employer'
            }]}
            onSelect={this.changeCategory}
          />
        </div>
        <Table
          headings={[{
            title: 'date',
            filter: 'date_created'
          }, {
            title: 'ad title'
          }, {
            title: 'author'
          }, {
            title: 'user role'
          }, {
            title: 'rating'
          }, {
            title: 'comment'
          }, {
            title: 'actions'
          }]}
          data={this.ratingData}
          isFetching={this.isFetching}
          onOrderChange={this.changeOrder}
          activeOrder={this.ordering}
          ratingColumn='rating'
          actionColumn='actions'
          deleteAction={this.deleteRating}
          hasDeleteAction
        />
        <Pagination
          totalPages={this.totalPages}
          currentPage={this.page}
          limit={this.limit}
          onLimitChange={this.changeLimit}
          onPagination={this.changePage}
        />
      </div>
    )
  }

}

export default UserRating
