exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_page__3_oMn {\n  padding: 0 25px;\n  width: 100%;\n  margin-right: 50px; }\n  .styles_page__header__1kneQ {\n    margin: 50px 0 36px 0; }\n    .styles_page__header_text__3RY7I {\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between; }\n      .styles_page__header_text__3RY7I h1 {\n        font-size: 36px;\n        font-weight: bold;\n        margin: 0; }\n    .styles_page__header_buttons__W23c0 {\n      display: flex;\n      flex-direction: row; }\n    .styles_page__header_btn__TtAJc {\n      width: 185px;\n      margin-right: 15px; }\n      .styles_page__header_btn__TtAJc:last-child {\n        margin-right: 0; }\n  .styles_page__back__1zrhR {\n    cursor: pointer;\n    padding: 8px 0;\n    display: inline-flex;\n    flex-direction: row;\n    align-items: center; }\n    .styles_page__back_icon__2D-Y6 {\n      -webkit-transform: rotate(90deg);\n              transform: rotate(90deg);\n      margin-right: 5px; }\n    .styles_page__back__1zrhR h4 {\n      color: #FF3E5F;\n      font-size: 16px;\n      margin: 0; }\n", ""]);

// Exports
exports.locals = {
	"page": "styles_page__3_oMn",
	"page__header": "styles_page__header__1kneQ",
	"page__header_text": "styles_page__header_text__3RY7I",
	"page__header_buttons": "styles_page__header_buttons__W23c0",
	"page__header_btn": "styles_page__header_btn__TtAJc",
	"page__back": "styles_page__back__1zrhR",
	"page__back_icon": "styles_page__back_icon__2D-Y6"
};