import axios from './axiosInstance'
import { conditionalGetParam } from '../helpers/utils'

export const adsApi = {
  getHelps: (limit, offset, ordering, subcategory) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `admin-app/helps/?${conditionalGetParam(subcategory, 'subcategory')}${conditionalGetParam(
            ordering,
            'ordering'
          )}limit=${limit}&offset=${offset}`
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getWorks: (limit, offset, ordering, subcategory) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `admin-app/work_ads/?${conditionalGetParam(
            subcategory,
            'subcategory'
          )}${conditionalGetParam(ordering, 'ordering')}limit=${limit}&offset=${offset}`
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  deleteHelp: id => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`admin-app/helps/${id}/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  deleteWork: id => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`admin-app/work_ads/${id}/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getSingleHelpAd: id => {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin-app/helps/${id}/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getSingleWorkAd: id => {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin-app/work_ads/${id}/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getHelpAdRequests: (id, limit, offset, status, ordering, timeFrom, timeTo) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `admin-app/helps/${id}/requests/?${conditionalGetParam(
            status,
            'status'
          )}${conditionalGetParam(timeFrom, 'time_from')}${conditionalGetParam(
            timeTo,
            'time_to'
          )}${conditionalGetParam(ordering, 'ordering')}limit=${limit}&offset=${offset}`
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getWorkAdRequests: (id, limit, offset, status, workDate, ordering) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `admin-app/work_ads/${id}/requests/?${conditionalGetParam(
            status,
            'status'
          )}${conditionalGetParam(workDate, 'work_date')}${conditionalGetParam(
            ordering,
            'ordering'
          )}limit=${limit}&offset=${offset}`
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
