exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_preloader__2nI7l {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  visibility: hidden; }\n  .styles_preloader__spinner__2anyo {\n    border: 8px solid #E6E6E6;\n    border-top: 8px solid #FF3E5F;\n    border-radius: 50%;\n    width: 80px;\n    height: 80px;\n    -webkit-animation: styles_spin__1lSzn 1.2s linear infinite;\n            animation: styles_spin__1lSzn 1.2s linear infinite;\n    z-index: 2; }\n  .styles_preloader__background__1VBtx {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    background: #000000;\n    opacity: 0.5; }\n  .styles_preloader-visible__30zTk {\n    visibility: visible; }\n\n@-webkit-keyframes styles_spin__1lSzn {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg); }\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg); } }\n\n@keyframes styles_spin__1lSzn {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg); }\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg); } }\n", ""]);

// Exports
exports.locals = {
	"preloader": "styles_preloader__2nI7l",
	"preloader__spinner": "styles_preloader__spinner__2anyo",
	"spin": "styles_spin__1lSzn",
	"preloader__background": "styles_preloader__background__1VBtx",
	"preloader-visible": "styles_preloader-visible__30zTk"
};