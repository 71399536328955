import OutsideClickHandler from 'react-outside-click-handler'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { observable, computed } from 'mobx'
import types from 'prop-types'
import styles from './styles.module.scss';
import errorIcon from '../../../assets/icons/error.svg'
import eyeIcon from '../../../assets/icons/eye.svg'
import Icon from '../../atoms/Icon'


@observer
class Input extends Component {

  // ------- Prop types -------
  static propTypes = {
    handleChange: types.func,
    maxLength: types.number,
    required: types.bool,
    hasError: types.bool,
    type: types.string,
    label: types.string,
    withTooltip: types.bool,
    autoFocus: types.bool
  }

  static defaultProps = {
    type: 'text',
    label: '',
    handleChange: () => {},
    required: false,
    hasError: false
  }

  // ------- State -------
  @observable inputValue = ''
  @observable showPassword = false
  @observable type = 'text'
  @observable isOpen = false
  @observable isDirty = false
  @observable errorsArray = []

  // ------- Constructor -------
  constructor(props) {
    super(props)
    this.type = props.type
    if (this.props.value) {
      this.inputValue = this.props.value
    }
  }

  // ------- Methods -------
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.inputValue = nextProps.value
  }

  processInput = e => {
    this.inputValue = e.target.value;
    this.errorsArray = this.validateInput()
    this.props.handleChange({
      value: this.inputValue,
      hasError: this.errorsArray.length > 0,
      errors: this.errorsArray
    })
  }

  refreshInput = () => {
    this.inputValue = ''
    this.errorsArray = []
    this.isOpen = false
    this.isDirty = false
  }

  validateInput = () => {
    const errors = []
    if (this.inputValue && !this.inputValue.trim() &&
      this.props.required && this.isDirty) {
      errors.push('This field is required')
    } else if (!this.inputValue && this.props.required && this.isDirty) {
      errors.push('This field is required')
    }
    if (this.props.minLength && this.inputValue.length < this.props.minLength && this.isDirty) {
      errors.push(`Minimum length is ${this.props.minLength} characters`)
    }
    if (this.props.type === 'email') {
      !this.validateEmail(this.inputValue) && errors.push('Not a valid email address')
    }
    return errors
  }

  validateEmail = email => {
    const regExp = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regExp.test(email)
  }

  onOutsideClick = () => {
    this.isOpen = false
    if (this.isDirty) {
      this.errorsArray = this.validateInput()
      this.props.handleChange({
        value: this.inputValue,
        hasError: this.errorsArray.length > 0,
        errors: this.errorsArray
      })
    }
  }

  togglePassword = e => {
    e.preventDefault()
    e.stopPropagation()
    this.showPassword = !this.showPassword
    this.type === 'password' ? (this.type = 'text') : (this.type = 'password')
  }

  onInputClick = () => {
    this.isOpen = !this.isOpen
    this.inputRef.focus()
    this.isDirty = true
  }

  @computed get showError() {
    return this.props.withTooltip && this.props.hasError && this.isDirty && !this.isOpen
  }


  render() {

    const modifyInput = classNames(
      { [styles['filled']]: this.inputValue },
      { [styles['password']]: this.props.type === 'password' },
      { [styles['error']]: this.props.hasError && this.isDirty && !this.isOpen }
    )

    return (
      <OutsideClickHandler onOutsideClick={() => this.onOutsideClick()}>
        <div className={styles['input']}
             onClick={() => this.onInputClick()}
        >
          {this.showError &&
          <div className={styles['tooltip__wrapper']}>
            <div className={styles['tooltip__error']}>
              {this.errorsArray[0]}
            </div>
          </div>
          }
          {this.showError &&
            <div className={styles['input__error-wrap']}>
              <Icon
                colorFill="red"
                svg={errorIcon}
                isSmall
              />
            </div>
          }
          <input
            className={modifyInput}
            type={
              this.type === 'email'
                ? 'text'
                : this.type
            }
            onChange={this.processInput}
            value={this.inputValue || ''}
            autoFocus={this.props.autoFocus}
            ref={input => (this.inputRef = input)}
            placeholder=''
          />
          {this.props.label &&
            <label>
              {this.props.label}
            </label>
          }
          {this.props.type === 'password' &&
            <div className={styles['input__icon-password']}>
              <Icon
                svg={eyeIcon}
                onClick={e => this.togglePassword(e)}
              />
            </div>
          }
          <div className={styles['input__border']} />
          <div className={styles['input__border-active']} />
        </div>
      </OutsideClickHandler>
    )
  }

}

export default Input
