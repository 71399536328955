import React, { Component } from 'react'
import DropdownSelect from '../../molecules/DropdownSelect'
import Pagination from '../../organisms/Pagination'
import Table from '../../organisms/Table'
import { adsApi } from '../../../api'
import { addQueryParam, getQueryParam, removeQueryParam } from '../../../helpers/history'
import types from 'prop-types'
import { observer, inject } from 'mobx-react'
import { computed, observable } from 'mobx'
import { CURRENCY_DICTIONARY } from '../../../constants'
import { toast } from 'react-toastify'
import moment from 'moment'
import 'moment-timezone'

@inject('modalsStore')
@observer
class AdsWork extends Component {
  static propTypes = {
    categories: types.oneOfType([types.array, types.object])
  }

  @observable workAds = []
  @observable fieldList = []
  @observable ordering = ''
  @observable subcategory = ''
  @observable limit = 10
  @observable total = 0
  @observable page = 0
  @observable isFetching = false

  constructor(props) {
    super(props)
    this.page = Number.parseInt(getQueryParam('wP', 1))
    this.limit = getQueryParam('wL', 10)
    this.subcategory = getQueryParam('wC')
    this.ordering = getQueryParam('wO', '')
    this.loadData()
  }

  changePage = page => {
    this.page = page
    addQueryParam('wP', page)
    this.loadData()
  }

  changeLimit = limit => {
    this.limit = limit.value
    this.page = 1
    addQueryParam('wL', limit.value)
    addQueryParam('wP', 1)
    this.loadData()
  }

  changeCategory = category => {
    this.subcategory = category
    this.page = 1
    addQueryParam('hP', 1)
    if (this.subcategory.value) {
      addQueryParam('wC', category.value)
    } else {
      removeQueryParam('wC')
    }
    this.loadData()
  }

  changeOrder = param => {
    this.ordering = param
    addQueryParam('wO', this.ordering)
    this.loadData()
  }

  loadData = () => {
    this.isFetching = true
    adsApi
      .getWorks(
        this.limit,
        this.offset,
        this.ordering,
        this.subcategory ? this.subcategory.value : ''
      )
      .then(response => {
        this.total = response.count
        const readyData = []
        response.results.forEach(item => {
          const entity = {}
          entity.data = []
          entity.isOpen = false
          entity.id = item.id
          entity.data.push(
            `${moment(item.date_created).format('DD.MM.YYYY').toString()}\n${moment(
              item.date_created
            ).format('HH:mm')}`
          )

          entity.data.push(item.creator.full_name)
          entity.data.push(item.category_title)
          entity.data.push(item.interest.label)
          entity.data.push(item.title)
          entity.data.push(`${CURRENCY_DICTIONARY.getCurrency(item.currency)} ${item.hourly_rate}`)
          entity.data.push(item.hours_amount)
          entity.data.push(item.total_value)
          entity.data.push(item.status)
          entity.additionalInfo = []
          entity.additionalInfo.push(item.creator.email)
          entity.additionalInfo.push(item.description)
          entity.additionalInfo.push(item.time_zone)
          entity.additionalInfo.push(item.additional_requirements)
          entity.additionalInfo.push(
            `${item.street ? `${item.street}, ` : ''}
            ${item.city ? `${item.city}, ` : ''}
            ${item.country ? `${item.country}` : ' '}`
          )
          entity.availability = []
          item.work_dates.forEach(date => {
            const dayOfTheWeek = moment(date.date, 'YYYY-MM-DD').format('DD MMM')
            const timeFrom = item.time_zone
              ? moment(date.time_from).tz(item.time_zone).format('hh:mm A')
              : moment(date.time_from).format('hh:mm')
            const timeTo = item.time_zone
              ? moment(date.time_to).tz(item.time_zone).format('hh:mm A')
              : moment(date.time_to).format('hh:mm')
            entity.availability.push(`${dayOfTheWeek} ${timeFrom} - ${timeTo}`)
          })

          readyData.push(entity)
        })
        if (!readyData.length) {
          if (this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.workAds = readyData
          }
        } else {
          this.workAds = readyData
          this.fieldList = response.results
        }
      })
      .catch(error => {
        toast.error('Error when trying to get work ads')
      })
      .finally(() => {
        this.isFetching = false
      })
  }

  showInfo = index => {
    const shouldOpen = !this.workAds[index].isOpen
    for (let i = 0; i < this.workAds.length; i++) {
      this.workAds[i].isOpen = false
    }
    this.workAds[index].isOpen = shouldOpen
  }

  deleteWork = id => {
    this.props.modalsStore.openConfirmModal(
      'Deletion',
      'Are you sure you want to delete this work ad?',
      () => {
        adsApi
          .deleteWork(id)
          .then(response => {
            toast.success('Work Ad was deleted successfully')
            this.loadData()
          })
          .catch(error => {
            toast.error('Error when trying to delete help ad')
          })
      }
    )
  }

  editHelp = (id, fieldList, categories, regCategories) => {
    const field = fieldList.find(field => field.id === id)
    this.props.modalsStore.openWorksAdsModal(field, categories, regCategories, this.loadData)
  }

  @computed get offset() {
    return (this.page - 1) * this.limit
  }

  @computed get totalPages() {
    return Math.ceil(this.total / this.limit)
  }

  @computed get categoryLabel() {
    let label = 'All'
    if (this.subcategory) {
      for (let i = 0; i < this.props.categories.length; i++) {
        if (this.props.categories[i].value === Number.parseInt(this.subcategory.value)) {
          label = this.props.categories[i].label
          break
        }
      }
    }
    return label
  }

  render() {
    return (
      <>
        <DropdownSelect
          label="Subcategories"
          value={this.categoryLabel}
          options={this.props.categories}
          onSelect={this.changeCategory}
        />
        <Table
          headings={[
            {
              title: 'created',
              filter: 'date_created'
            },
            {
              title: 'user name',
              filter: 'creator__full_name'
            },
            {
              title: 'category',
              filter: 'interest__category__title'
            },
            {
              title: 'subcategory',
              filter: 'interest__title'
            },
            {
              title: 'title',
              filter: 'title'
            },
            {
              title: 'hourly rate'
            },
            {
              title: 'hours'
            },
            {
              title: 'total value'
            },
            {
              title: 'status',
              filter: 'is_active_status',
              reverse: true
            }
          ]}
          data={this.workAds}
          fieldList={this.fieldList}
          showInfoClick={this.showInfo}
          additionalHeadings={[
            'email',
            'description',
            'additional requirements',
            'location',
            'schedules'
          ]}
          onOrderChange={this.changeOrder}
          activeOrder={this.ordering}
          isFetching={this.isFetching}
          subCategories={this.categories}
          regCategories={this.props.regularCategories}
          onDelete={this.deleteWork}
          onEdit={this.editHelp}
          rowRedirect="/ad-details?type=Work"
          hasAdditionalInfo
        />
        <Pagination
          totalPages={this.totalPages}
          currentPage={this.page}
          limit={this.limit}
          onLimitChange={this.changeLimit}
          onPagination={this.changePage}
        />
      </>
    )
  }
}

export default AdsWork
