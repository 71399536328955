exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_modal-invite__1bf8E h4 {\n  font-size: 20px;\n  color: #3D3D3D;\n  font-family: \"Nunito Sans\", sans-serif;\n  text-align: center;\n  font-weight: 400;\n  margin: 18px 0; }\n\n.styles_modal-invite__buttons__2slAx {\n  display: flex;\n  justify-content: center; }\n  .styles_modal-invite__buttons__2slAx div {\n    width: 188px; }\n    .styles_modal-invite__buttons__2slAx div:first-child {\n      margin-right: 20px; }\n\n.styles_modal-invite__1bf8E form {\n  max-width: 400px;\n  margin: 0 auto; }\n\n.styles_modal-invite__input__15Gi_ {\n  margin-bottom: 20px; }\n  .styles_modal-invite__input-info__2UgRd {\n    font-size: 11px;\n    color: #B3B3B3; }\n    .styles_modal-invite__input-info__2UgRd p {\n      margin-bottom: 45px;\n      margin-top: 2px; }\n", ""]);

// Exports
exports.locals = {
	"modal-invite": "styles_modal-invite__1bf8E",
	"modal-invite__buttons": "styles_modal-invite__buttons__2slAx",
	"modal-invite__input": "styles_modal-invite__input__15Gi_",
	"modal-invite__input-info": "styles_modal-invite__input-info__2UgRd"
};