import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Icon from '../../atoms/Icon'
import searchIcon from '../../../assets/icons/search.svg'
import crossIcon from '../../../assets/icons/x.svg'
import styles from './styles.module.scss'
import types from 'prop-types'


@observer
class SearchInput extends Component {

  static propTypes = {
    onChange: types.func,
    placeholder: types.string
  }

  static defaultProps = {
    placeholder: ''
  }

  @observable inputValue = ''

  constructor(props) {
    super(props)
    if (this.props.value) {
      this.inputValue = this.props.value
    }
  }

  clearInput = () => {
    this.inputValue = ''
    this.props.onChange(this.inputValue)
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.inputValue = nextProps.value
  }

  render() {
    return (
      <div className={styles['search-input']}>
        <input
          type='text'
          value={this.inputValue || ''}
          placeholder={this.props.placeholder}
          onChange={e => this.props.onChange(e.target.value)}
        />
        <div className={styles['search-input__clearIcon']}
             onClick={this.clearInput}
        >
          <Icon
            svg={crossIcon}
            colorStroke='black'
          />
        </div>
        <div className={styles['search-input__icon']}>
          <Icon
            svg={searchIcon}
            colorStroke='black'
          />
        </div>
      </div>
    )
  }

}

export default SearchInput
