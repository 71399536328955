import ReactSVG from 'react-svg'
import types from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'

Icon.propTypes = {
  onClick: types.func,
  colorStroke: types.string,
  colorFill: types.string,
  gFill: types.string,
  svg: types.string,
  isSmall: types.bool
}

Icon.defaultProps = {
  onClick: () => {}
}

export default function Icon(props) {

  const modifyIcon = classNames(
    styles['icon'],
    {
      [styles[`icon__image--${props.colorStroke}-stroke`]]: props.colorStroke,
      [styles[`icon__image--${props.colorFill}-fill`]]: props.colorFill,
      [styles[`icon__image--${props.gFill}-gFill`]]: props.gFill,
      [styles['icon__small']]: props.isSmall
    }
  );

  return (
    <div className={modifyIcon} onClick={props.onClick}>
      <ReactSVG src={props.svg} className={modifyIcon} />
    </div>
  )

}
