import React from 'react'
import types from 'prop-types'
import Icon from '../../atoms/Icon'
import starFull from '../../../assets/icons/star_full.svg'
import starEmpty from '../../../assets/icons/star_outline.svg'
import styles from './styles.module.scss'

Rating.propTypes = {
  rating: types.number
}

export default function Rating(props) {

  return (
    <div className={styles['rating']}>
      {props.rating &&
        <>
          {Array.apply(null, Array(5)).map((item, index) => {
            return (
              <div key={index}>
                {(index + 1) <= props.rating ?
                  <Icon
                    svg={starFull}
                  />
                  :
                  <Icon
                    svg={starEmpty}
                  />
                }
              </div>
            )
          })}
        </>
      }
    </div>
  )

}
