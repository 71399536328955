import React, { Component } from 'react'
import styles from './styles.module.scss'
import Card from '../../atoms/Card'
import Text from '../../atoms/Text'
import { observer } from 'mobx-react'
import types from 'prop-types'
import _isEmpty from 'lodash.isempty';
import { CURRENCY_DICTIONARY } from '../../../constants'

@observer
class UserInfo extends Component {
  static propTypes = {
    userInfo: types.oneOfType([types.array, types.object]),
    userContacts: types.oneOfType([types.array, types.object]),
    userActivity: types.oneOfType([types.array, types.object]),
    userIncomes: types.oneOfType([types.array, types.object])
  }

  render() {
    return (
      <div className={styles['user-info']}>
        <div className={styles['user-info__row']}>
          {this.props.userInfo && (
            <div className={styles['user-info__card']}>
              <Card header="Client Information">
                {Object.entries(this.props.userInfo).map(([key, value], index) => {
                  return (
                    <div className={styles['user-info__card-row']} key={index}>
                      <div>{key}</div>
                      <Text text={value} />
                    </div>
                  )
                })}
              </Card>
            </div>
          )}
          {this.props.userContacts && (
            <div className={styles['user-info__card']}>
              <Card header="Contact Information">
                {Object.entries(this.props.userContacts).map(([key, value], index) => {
                  return (
                    <div className={styles['user-info__card-row']} key={index}>
                      <div>{key}</div>
                      <Text text={value} />
                    </div>
                  )
                })}
              </Card>
            </div>
          )}
          {this.props.userActivity && (
            <div className={styles['user-info__card']}>
              <Card header="Activity">
                {Object.entries(this.props.userActivity).map(([key, value], index) => {
                  return (
                    <div className={styles['user-info__card-row']} key={index}>
                      <div>{key}</div>
                      <Text text={value} />
                    </div>
                  )
                })}
                <div className={styles['user-info__card-row']}>
                  <div>Money earned</div>
                  <div>
                    {!_isEmpty(this.props.userIncomes.earned) ? (
                      Object.entries(this.props.userIncomes.earned).map(([key, value], index) => (
                        <Text
                          key={index}
                          text={`${CURRENCY_DICTIONARY.getCurrency(key)} ${value}`}
                        />
                      ))
                    ) : (
                      <Text text={0} />
                    )}
                  </div>
                </div>
                <div className={styles['user-info__card-row']}>
                  <div>Money spent</div>
                  <div>
                    {!_isEmpty(this.props.userIncomes.spent) ? (
                      Object.entries(this.props.userIncomes.spent).map(([key, value], index) => (
                        <Text
                          key={index}
                          text={`${CURRENCY_DICTIONARY.getCurrency(key)} ${value}`}
                        />
                      ))
                    ) : (
                      <Text text={0} />
                    )}
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default UserInfo
