import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import moment from 'moment'
import SidebarItem from '../../molecules/SidebarItem'
import { Link } from 'react-router-dom'

import adsIcon from '../../../assets/icons/clients.svg'
import usersIcon from '../../../assets/icons/people.svg'
import accountIcon from '../../../assets/icons/profile.svg'
import logoutIcon from '../../../assets/icons/logout.svg'
import logo from '../../../assets/images/logo.png'
import styles from './styles.module.scss'


@withRouter
@inject('commonStore')
@observer
class Sidebar extends Component {

  constructor(props) {
    super(props)
    this.props.commonStore.getAdminInfo()
  }

  render() {
    return (
      <>
        <div className={styles['sidebar__placeholder']}/>
        <aside className={styles['sidebar']}>
          <div className={styles['sidebar__bg']}/>
          <Link to="/">
            <div className={styles['sidebar__menu-logo-wrap']}>
              <img className={styles['sidebar__menu-logo']}
                   src={logo}
                   alt=""
              />
            </div>
          </Link>
          <nav className={styles['sidebar__menu-top']}>
            <SidebarItem
              svg={adsIcon}
              text="ads"
              route="/"
              isActive={this.props.history.location.pathname === '/' ||
                this.props.history.location.pathname === '/ad-details'}
            />
            <SidebarItem
              svg={usersIcon}
              text="users"
              route="/users"
              isActive={this.props.history.location.pathname === '/users' ||
                this.props.history.location.pathname === '/user-details'}
              setStroke
            />
          </nav>
          <nav className={styles['sidebar__menu-bottom']}>
            <SidebarItem
              svg={accountIcon}
              text={this.props.commonStore.adminName}
            />
            <SidebarItem
              svg={logoutIcon}
              onClick={() => this.props.commonStore.logOut()}
              text="logout"
            />
          </nav>
          <div className={styles['sidebar__copyright']}>
            {`Collabri © ${moment().format('YYYY')}. All rights reserved.`}
          </div>
        </aside>
      </>
    )
  }

}

export default Sidebar
