import React, { Component } from 'react'
import PageWrap from '../../prototypes/PageWrap'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { addQueryParam, getQueryParam } from '../../../helpers/history'
import AdsHelp from '../../prototypes/AdsHelp'
import AdsWork from '../../prototypes/AdsWork'
import Tabs from '../../atoms/Tabs'
import { commonApi } from '../../../api'
import { toast } from 'react-toastify'


@observer
class Ads extends Component {

  @observable categories = []
  @observable regularCategories = []
  @observable activeTab = ''

  constructor(props) {
    super(props)
    this.activeTab = getQueryParam('tab', 'Help')
    commonApi.getCategories()
      .then(response => {
        this.categories.push({ label: 'All' })
        this.categories.push(...response.map(item => {
          return {
            label: item.label === 'Other' ? `Others / ${item.category_label}` : item.label,
            value: item.id
          }
        }))
      })
      .catch(error => {
        toast.error('Error when trying to get categories')
      })
    commonApi.getRegularCategories()
      .then(response => {
        this.regularCategories.push(...response.map(item => {
          return {
            label: item.label,
            value: item.id
          }
        }))
      })
      .catch(error => {
        toast.error('Error when trying to get categories')
      })
  }

  changeTab = tabName => {
    addQueryParam('tab', tabName)
  }

  render() {
    return (
      <PageWrap
        heading='Ads'
      >
        <Tabs
          activeTab={this.activeTab}
          tabNames={['Help', 'Work']}
          onTabChange={this.changeTab}
        >
          <AdsHelp regularCategories={this.regularCategories} categories={this.categories} />
          <AdsWork regularCategories={this.regularCategories} categories={this.categories} />
        </Tabs>
      </PageWrap>
    )
  }

}

export default Ads
