import React, { Component } from 'react'
import { LastLocationProvider } from 'react-router-last-location'
import { Switch } from 'react-router-dom'
import routes from '../routes'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import ConfirmModal from './organisms/ConfirmModal'
import Sidebar from './organisms/Sidebar'
import Preloader from './atoms/Preloader'
import classNames from 'classnames'
import EditModal from "./organisms/EditAdsModal";
import WorksModal from "./organisms/WorksAdsModal";


@withRouter
@inject('commonStore')
@observer
class App extends Component {

  render() {
    const showSidebar = this.props.history.location.pathname.split('/')[1] !== 'login'

    return (
      <div>
        <main className={classNames('main', !showSidebar && 'main__noSidebar')}>
          <LastLocationProvider>
            {showSidebar && <Sidebar />}
            <Switch>{routes}</Switch>
          </LastLocationProvider>
        </main>
        <Preloader visible={this.props.commonStore.preloaderVisible} />
        <ToastContainer
          pauseOnHover={false}
          toastClassName="toast-notification"
          progressClassName="toast-notification__progress"
        />
        <ConfirmModal />
        <EditModal big/>
        <WorksModal big/>
      </div>
    )
  }
}

export default App
