import React from 'react'
import types from 'prop-types'
import styles from './styles.module.scss'

PreloaderPage.propTypes = {
  absolute: types.bool,
  transparent: types.bool
}

export default function PreloaderPage(props) {

  return (
    <div className={styles['preloader-page']} style={{
      position: props.absolute ? 'absolute' : 'relative'}}
    >
      <div className={styles['preloader-page__spinner']} />
      <div className={styles['preloader-page__background']} />
    </div>
  )

}
