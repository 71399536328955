import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import DropdownSelect from '../../molecules/DropdownSelect'
import Pagination from '../../organisms/Pagination'
import Table from '../../organisms/Table'
import { adsApi } from '../../../api'
import {
  getQueryParam,
  addQueryParam,
  removeQueryParam
} from '../../../helpers/history'
import types from 'prop-types'
import { observer, inject } from 'mobx-react'
import { observable, computed } from 'mobx'
import { CURRENCY_DICTIONARY } from '../../../constants'
import { toast } from 'react-toastify'
import moment from 'moment'


@withRouter
@inject('modalsStore')
@observer
class AdsHelp extends Component {

  static propTypes = {
    categories: types.oneOfType([types.array, types.object]),
    regularCategories: types.oneOfType([types.array, types.object])
  }

  @observable helpAds = []
  @observable fieldList = []
  @observable ordering = ''
  @observable subcategory = ''
  @observable limit = 10
  @observable total = 0
  @observable page = 0
  @observable isFetching = false

  constructor(props) {
    super(props)
    this.page = Number.parseInt(getQueryParam('hP', 1))
    this.limit = getQueryParam('hL', 10)
    this.subcategory = getQueryParam('hC')
    this.ordering = getQueryParam('hO', '')
    this.loadData()
  }

  changePage = page => {
    this.page = page
    addQueryParam('hP', page)
    this.loadData()
  }

  changeLimit = limit => {
    this.limit = limit.value
    this.page = 1
    addQueryParam('hL', limit.value)
    addQueryParam('hP', 1)
    this.loadData()
  }

  changeCategory = category => {
    this.subcategory = category
    this.page = 1
    addQueryParam('hP', 1)
    if (this.subcategory.value) {
      addQueryParam('hC', category.value)
    } else {
      removeQueryParam('hC')
    }
    this.loadData()
  }

  changeOrder = (param) => {
    this.ordering = param
    addQueryParam('hO', this.ordering)
    this.loadData()
  }

  loadData = () => {
    this.isFetching = true
    adsApi.getHelps(this.limit, this.offset, this.ordering,
      this.subcategory ? this.subcategory.value : '')
      .then(response => {
        this.total = response.count
        const readyData = []
        response.results.forEach((item) => {
          const entity = {}
          entity.data = []
          entity.isOpen = false
          entity.id = item.id
          entity.data.push(`${moment(item.date_created).format('DD.MM.YYYY').toString()}\n${moment(item.date_created).format('HH:mm')}`)
          entity.data.push(item.creator.full_name)
          entity.data.push(item.category_title)
          entity.data.push(item.interest.label)
          entity.data.push(item.title)
          entity.data.push(`${CURRENCY_DICTIONARY.getCurrency(item.currency)} ${item.hourly_rate}`)
          entity.data.push(item.status)
          entity.additionalInfo = []
          entity.additionalInfo.push(item.creator.email)
          entity.additionalInfo.push(item.description)
          entity.additionalInfo.push(
            `${item.street ? `${item.street}, ` : ''}
            ${item.city ? `${item.city}, ` : ''}
            ${item.country ? `${item.country}` : ''}`
          )
          const timeFrom = moment(item.availability.time_from, 'HH:mm:s').format('HH:mm')
          const timeTo = moment(item.availability.time_to, 'HH:mm:s').format('HH:mm')
          entity.availability = []
          entity.availability = (Object.entries(item.availability).filter(([key, value]) => {
            return key !== 'time_from' && key !== 'time_to' && value
          }).map(item => {
            return `${item[0]} ${timeFrom} - ${timeTo}`
          }))
          readyData.push(entity)
        })
        if (!readyData.length) {
          if (this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.helpAds = readyData
          }
        } else {
          this.helpAds = readyData
          this.fieldList = response.results
        }
      })
      .catch(error => {
        toast.error('Error when trying to get help ads')
      })
      .finally(() => {
        this.isFetching = false
      })
  }

  showInfo = index => {
    const shouldOpen = !this.helpAds[index].isOpen
    for (let i = 0; i < this.helpAds.length; i++) {
      this.helpAds[i].isOpen = false
    }
    this.helpAds[index].isOpen = shouldOpen
  }

  deleteHelp = id => {
    this.props.modalsStore.openConfirmModal(
      'Deletion',
      'Are you sure you want to delete this help ad?',
      () => {
        adsApi.deleteHelp(id)
          .then(response => {
            toast.success('Help Ad was deleted successfully')
            this.loadData()
          })
          .catch(error => {
            toast.error('Error when trying to delete help ad')
          })
      }
    )
  }

  editHelp = (id,fieldList,categories,regCategories) => {
    const field = fieldList.find((field) => field.id === id);
    this.props.modalsStore.openEditAdsModal(
      field,categories, regCategories, this.loadData
    )
  }

  @computed get offset() {
    return (this.page - 1) * this.limit
  }

  @computed get totalPages() {
    return Math.ceil(this.total / this.limit)
  }

  @computed get categoryLabel() {
    let label = 'All'
    if (this.subcategory) {
      for (let i = 0; i < this.props.categories.length; i++) {
        if (this.props.categories[i].value === Number.parseInt(this.subcategory.value)) {
          label = this.props.categories[i].label
          break
        }
      }
    }
    return label
  }

  render() {
    return(
      <>
        <DropdownSelect
          label="Subcategories"
          value={this.categoryLabel}
          options={this.props.categories}
          onSelect={this.changeCategory}
        />
        <Table
          headings={[{
            title: 'created',
            filter: 'date_created'
          }, {
            title: 'user name',
            filter: 'creator__full_name'
          }, {
            title: 'category',
            filter: 'interest__category__title'
          }, {
            title: 'subcategory',
            filter: 'interest__title'
          }, {
            title: 'title',
            filter: 'title'
          }, {
            title: 'hourly rate'
          }, {
            title: 'status',
            filter: 'status'
          }]}
          data={this.helpAds}
          fieldList={this.fieldList}
          showInfoClick={this.showInfo}
          additionalHeadings={['email', 'description', 'location', 'availabilities']}
          onOrderChange={this.changeOrder}
          activeOrder={this.ordering}
          isFetching={this.isFetching}
          subCategories={this.props.categories}
          regCategories={this.props.regularCategories}
          onDelete={this.deleteHelp}
          onEdit={this.editHelp}
          rowRedirect='/ad-details?type=Help'
          hasAdditionalInfo
        />
        <Pagination
          totalPages={this.totalPages}
          currentPage={this.page}
          limit={this.limit}
          onLimitChange={this.changeLimit}
          onPagination={this.changePage}
        />
      </>
    )
  }

}

export default AdsHelp
