const CURRENCY_DICTIONARY = {
  usd: '$',
  gbp: '£',
  chf: 'CHF',
  eur: '€'
}

const DEFAULT_CURRENCY = 'eur';

CURRENCY_DICTIONARY.getCurrency = currency => {
  return CURRENCY_DICTIONARY[currency] || CURRENCY_DICTIONARY[DEFAULT_CURRENCY];
}

export {
  CURRENCY_DICTIONARY
}
