import React, { memo, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import OutsideClickHandler from 'react-outside-click-handler'
import ReactDOM from 'react-dom'
import styles from './styles.module.scss'
import cn from 'classnames';

function Modal(props) {

  const ref = useRef(null)

  // useEffect(() => {
  //   if (props.open) {
  //     document.body.setAttribute('class', 'overflow-hidden')
  //   } else {
  //     setTimeout(() => {
  //       document.body.removeAttribute('class')
  //     }, 300)
  //   }
  // }, [props.open])

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.open}
      timeout={50}
      unmountOnExit
      classNames={styles['modal__backdrop']}
    >
      <div className={styles['modal__backdrop']}>
        <OutsideClickHandler onOutsideClick={props.onClose ? props.onClose : () => {}}>
          <div
            className={cn(styles['modal'],{ [styles['modal--big']]: props.big })}
            ref={ref}
          >
            {props.children}
          </div>
        </OutsideClickHandler>
      </div>
    </CSSTransition>,
    document.body
  )

}

export default memo(Modal)
