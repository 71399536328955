import React from 'react'
import Icon from  '../../atoms/Icon'
import Button from '../../molecules/Button'
import dropIcon from '../../../assets/icons/drop.svg'
import history from '../../../routes/history'
import types from 'prop-types'
import styles from './styles.module.scss'

PageWrap.propTypes = {
  heading: types.string,
  hasBackBtn: types.bool,
  backBtnText: types.string,
  buttonText: types.string,
  secondBtnText: types.string,
  hasBtn: types.bool,
  hasSecondBtn: types.bool,
  onSecondBtnClick: types.func,
  onBtnClick: types.func
}

PageWrap.defaultProps = {
  backBtnText: 'Back',
  onBtnClick: () => {}
}

export default function PageWrap(props) {

  return (
    <div className={styles['page']}>
      <div className={styles['page__header']}>
        {props.hasBackBtn &&
          <div className={styles['page__back']}
               onClick={() => history.goBack()}
          >
            <div className={styles['page__back_icon']}>
              <Icon
                svg={dropIcon}
                colorFill='red'
              />
            </div>
            <h4>{props.backBtnText}</h4>
          </div>
        }
        <div className={styles['page__header_text']}>
          <h1>{props.heading}</h1>
          <div className={styles['page__header_buttons']}>
            {props.hasSecondBtn &&
            <div className={styles['page__header_btn']}>
              <Button
                text={props.secondBtnText}
                onClick={props.onSecondBtnClick}
                isGreen
              />
            </div>
            }
            {props.hasBtn &&
            <div className={styles['page__header_btn']}>
              <Button
                text={props.buttonText}
                onClick={props.onBtnClick}
              />
            </div>
            }
          </div>
        </div>
      </div>
      {props.children}
    </div>
  )

}
