exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_pagination__3e7mn {\n  display: flex;\n  flex-direction: row;\n  font-size: 12px; }\n  .styles_pagination__wrapper__2Sr0U {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 20px 0; }\n  .styles_pagination__button__6OqVn {\n    background: #FFFFFF;\n    width: 22px;\n    height: 22px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 3px;\n    cursor: pointer; }\n    .styles_pagination__button__6OqVn:hover {\n      background: #EBEBEB; }\n    .styles_pagination__button--prev__9OpeE {\n      margin-right: 8px; }\n      .styles_pagination__button--prev__9OpeE svg {\n        -webkit-transform: rotate(90deg);\n                transform: rotate(90deg); }\n    .styles_pagination__button--next__3IuLN {\n      margin-left: 8px; }\n      .styles_pagination__button--next__3IuLN svg {\n        -webkit-transform: rotate(-90deg);\n                transform: rotate(-90deg); }\n  .styles_pagination-page__1gqps {\n    padding: 2px 8px;\n    cursor: pointer;\n    font-size: 12px;\n    background: #FFFFFF;\n    display: flex;\n    align-items: center;\n    color: #000000;\n    -webkit-user-select: none;\n            user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none; }\n    .styles_pagination-page__1gqps:hover {\n      background: #EBEBEB; }\n    .styles_pagination-page--active__12iOe {\n      background: #FF3E5F;\n      color: #FFFFFF;\n      border-radius: 3px; }\n      .styles_pagination-page--active__12iOe:hover {\n        background: #FF3E5F;\n        cursor: default; }\n", ""]);

// Exports
exports.locals = {
	"pagination": "styles_pagination__3e7mn",
	"pagination__wrapper": "styles_pagination__wrapper__2Sr0U",
	"pagination__button": "styles_pagination__button__6OqVn",
	"pagination__button--prev": "styles_pagination__button--prev__9OpeE",
	"pagination__button--next": "styles_pagination__button--next__3IuLN",
	"pagination-page": "styles_pagination-page__1gqps",
	"pagination-page--active": "styles_pagination-page--active__12iOe"
};