import OutsideClickHandler from 'react-outside-click-handler'
import React, { Component } from 'react'
import types from 'prop-types'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Icon from '../../atoms/Icon'
import dropIcon from '../../../assets/icons/drop.svg'
import classNames from 'classnames'
import styles from './styles.module.scss'


@observer
class DropdownSelect extends Component {

  static propTypes = {
    label: types.string,
    topOptionPosition: types.bool,
    options: types.oneOfType([types.array, types.object]),
    onSelect: types.func,
    value: types.string,
    forPagination: types.bool
  }

  static defaultProps = {
    onSelect: () => {}
  }

  @observable isOpen = false
  @observable value = 'All'

  constructor(props) {
    super(props)
    this.value = props.value
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.value = nextProps.value
  }

  selectOption = option => {
    this.isOpen = false
    this.props.onSelect(option)
  }

  render() {
    return (
      <div className={classNames(styles['dropdown'],
        this.props.forPagination && styles['dropdown__pagination'])}
      >
        <label>{this.props.label}</label>
        <OutsideClickHandler onOutsideClick={() => {
          this.isOpen = false
        }}>
          <div className={styles['dropdown__input']}
               onClick={() => this.isOpen = !this.isOpen}
          >
            <input
              type='text'
              value={this.value || ''}
              readOnly
            />
            <div className={styles['dropdown__icon']}>
              <Icon
                svg={dropIcon}
                colorFill={this.props.forPagination ? 'white' : ''}
              />
            </div>
          </div>
          {this.isOpen &&
            <div className={classNames(styles['dropdown__options'],{[styles['dropdown__options--top']]:this.props.topOptionPosition})}>
              {this.props.options.filter(item => item.label !== this.value).map((item, index) => {
                return (
                <div className={styles['dropdown__option']}
                     key={index}
                     onClick={() => this.selectOption(item)}
                >
                  {item.content ? item.content : item.label}
                </div>
                )})
              }
            </div>
          }
        </OutsideClickHandler>
      </div>
    )
  }

}

export default DropdownSelect
