import React from 'react'
import types from 'prop-types'
import styles from './styles.module.scss'
import Icon from '../../atoms/Icon'
import { Link } from 'react-router-dom'
import ConditionalWrapper from '../../prototypes/ConditionalWrapper'
import classNames from 'classnames'

SidebarItem.propTypes = {
  route: types.string,
  svg: types.string,
  text: types.string,
  onClick: types.func,
  isActive: types.bool,
  setStroke: types.bool
}

SidebarItem.defaultProps = {
  onClick: () => {}
}

export default function SidebarItem(props) {

  return (
    <div className={styles['sidebar__item--wrap']}>
      <ConditionalWrapper
        condition={props.route}
        wrapper={children =>
          <Link to={props.route}>
            {children}
          </Link>
        }
      >
        <div className={classNames(styles['sidebar__item'],
          props.isActive && styles['sidebar__item-active'])}
             onClick={props.onClick}
        >
          {props.isActive &&
          <div className={styles['sidebar__item--mark']}/>
          }
          <div className={styles['sidebar__icon']}>
            <Icon
              colorFill={props.isActive ? 'red' : 'light-gray'}
              gFill={(props.isActive && props.setStroke) ? 'red' : ''}
              svg={props.svg}
            />
          </div>
          <h4 className={styles['sidebar__text']}>
            {props.text}
          </h4>
        </div>
      </ConditionalWrapper>
    </div>
  )

}
