import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import PageWrap from '../../prototypes/PageWrap'
import UsersRegistered from '../../prototypes/UsersRegistered'
import UsersInvited from '../../prototypes/UsersInvited'
import Tabs from '../../atoms/Tabs'
import { addQueryParam, getQueryParam } from '../../../helpers/history'


@observer
class Users extends Component {

  @observable activeTab = ''

  constructor(props) {
    super(props)
    this.activeTab = getQueryParam('tab', 'Registered')
  }

  changeTab = tabName => {
    addQueryParam('tab', tabName)
  }

  render() {
    return(
      <PageWrap
        heading='Clients'
      >
        <Tabs
          activeTab={this.activeTab}
          tabNames={['Registered', 'Invited']}
          onTabChange={this.changeTab}
        >
          <UsersRegistered />
          <UsersInvited />
        </Tabs>
      </PageWrap>
    )
  }

}

export default Users
