import axios from './axiosInstance'

export const commonApi = {

  getCategories : () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`work/subcategories/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getSubCategories : (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`work/categories/${id}/subcategories/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getRegularCategories : () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/work/categories/`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}
