import React from 'react'
import Icon from '../../atoms/Icon'
import types from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.scss'

Button.propTypes = {
  text: types.string,
  disabled: types.oneOfType([types.func, types.bool]),
  onClick: types.func,
  type: types.string,
  isGray: types.bool,
  isWhite: types.bool,
  isGreen: types.bool,
  iconSvg: types.string,
  smaller: types.bool
}

Button.defaultProps = {
  onClick: () => {}
}

export default function Button(props) {

  return (
    <button
      className={classNames(styles['button'],
        props.isGray && styles['button--gray'],
        props.isWhite && styles['button--white'],
        props.smaller && styles['button--smaller'],
        props.isGreen && styles['button--green'])}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type || 'button'}
    >
      {props.iconSvg &&
        <span className={styles['button__icon']}>
        <Icon
          svg={props.iconSvg}
        />
      </span>
      }
      <span className={styles['button__text']}>
        {props.text}
      </span>
    </button>
  )

}
