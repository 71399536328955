import React from 'react'
import types from 'prop-types'

Text.propTypes = {
  text: types.oneOfType([types.string, types.number])
}

export default function Text(props) {
    return (
      (props.text || props.text === 0) ? (
      props.text.toString() ? props.text.toString().split('\n').map((i, key) => {
        return i.length ? <p key={key} style={{margin: 0}}>{i}</p> : <br key={key}/>
      }) : props.text.toString()) : <p style={{color: '#808080', margin: 0}} >None</p>
    )
}
