exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_sidebar__item--wrap__xws2P a {\n  text-decoration: none; }\n\n.styles_sidebar__item__11r3V {\n  display: flex;\n  cursor: pointer;\n  height: 42px;\n  align-items: center;\n  margin: 22px 0;\n  padding: 0 15px 0 38px;\n  position: relative; }\n  .styles_sidebar__item__11r3V:hover .styles_sidebar__text__IVwcO {\n    color: #FFFFFF; }\n  .styles_sidebar__item--mark__3t1PL {\n    width: 4px;\n    background: red;\n    height: 100%;\n    position: absolute;\n    left: 0;\n    border-radius: 0 100px 100px 0; }\n  .styles_sidebar__item-active__33MGM .styles_sidebar__text__IVwcO {\n    color: #FFFFFF; }\n\n.styles_sidebar__icon__4L126 {\n  width: 34px;\n  display: flex;\n  justify-content: center; }\n\n.styles_sidebar__text__IVwcO {\n  text-transform: capitalize;\n  color: #B3B3B3;\n  font-size: 20px;\n  padding-left: 24px;\n  font-weight: 600;\n  margin: 0; }\n", ""]);

// Exports
exports.locals = {
	"sidebar__item--wrap": "styles_sidebar__item--wrap__xws2P",
	"sidebar__item": "styles_sidebar__item__11r3V",
	"sidebar__text": "styles_sidebar__text__IVwcO",
	"sidebar__item--mark": "styles_sidebar__item--mark__3t1PL",
	"sidebar__item-active": "styles_sidebar__item-active__33MGM",
	"sidebar__icon": "styles_sidebar__icon__4L126"
};