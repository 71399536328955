import { observable, reaction, action } from 'mobx'
import history from '../routes/history'
import { authApi } from '../api/auth.api'
import { toast } from 'react-toastify'

class CommonStore {

  @observable tokenPair = JSON.parse(window.localStorage.getItem('jwt'))

  @observable preloaderVisible = false
  @observable refreshRequest = false
  @observable adminName = ''

  constructor() {
    reaction(
      () => this.tokenPair,
      tokenPair => {
        if (tokenPair) {
          window.localStorage.setItem('jwt', JSON.stringify(tokenPair))
        } else {
          window.localStorage.removeItem('jwt')
        }
      }
    )
  }

  @action authorize(tokenPair) {
    this.tokenPair = tokenPair
    history.replace('/')
  }

  @action logOut() {
    this.refreshRequest = false
    this.tokenPair = undefined
    history.push('/login')
  }

  @action refreshToken() {
    return new Promise((resolve, reject) => {
      if (this.tokenPair) {
        authApi.refreshToken(this.tokenPair.refresh)
          .then(response => {
            this.tokenPair = {
              access: response.access,
              refresh: this.tokenPair.refresh
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      } else {
        reject()
      }
    })
  }

  @action showPreloader() {
    this.preloaderVisible = true
  }

  @action hidePreloader() {
    this.preloaderVisible = false
  }

  @action getAdminInfo() {
    authApi.whoAmI()
      .then(response => {
        this.adminName = response.full_name
      })
      .catch(error => {
        toast.error('Error when trying to get admin user info')
      })
  }

}

export default new CommonStore()
