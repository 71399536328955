import axios from 'axios'
import commonStore from '../store/common.store'


let baseURLFromEnv

switch (process.env.REACT_APP_HOST_ENV) {
    case 'dev':
        baseURLFromEnv = 'https://api.collabri-dev.scenario-projects.com'
        break
    case 'stage':
        baseURLFromEnv = 'https://api.collabri-stage.scenario-projects.com'
        break
    case 'preprod':
        baseURLFromEnv = 'https://api.collabri-preprod.scenario-projects.com'
        break
    case 'prod':
        baseURLFromEnv = 'https://back.collabri.app'
        break
    default:
        baseURLFromEnv = 'https://api.collabri-dev.scenario-projects.com'
}

const axiosInstance = axios.create({
    baseURL: baseURLFromEnv,
    timeout: 60000
})

axiosInstance.interceptors.request.use(config => {
  if (commonStore.tokenPair) {
    config.headers.Authorization = `JWT ${commonStore.tokenPair.access}`
  }
  return config
})

function createAxiosResponseInterceptor()  {
  const interceptor = axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status !== 401) {
        return Promise.reject(error)
      }

      axios.interceptors.response.eject(interceptor)

      commonStore.refreshToken()
        .then(() => {
          if (!commonStore.refreshRequest) {
            commonStore.refreshRequest = true
            error.response.config.headers['Authorization'] = `JWT ${commonStore.tokenPair.access}`
            return axiosInstance(error.response.config)
          } else {
            commonStore.logOut()
            return Promise.reject(error)
          }
        })
        .catch(error => {
          commonStore.logOut()
          return Promise.reject(error)
        })
        .finally(() => createAxiosResponseInterceptor())
    }
  )
}

createAxiosResponseInterceptor()

export default axiosInstance
