import React, { Component } from 'react'
import Modal from '../../prototypes/Modal'
import Button from '../../molecules/Button'
import Input from '../../../components/molecules/Input'
import { observer, inject } from 'mobx-react'
import TimeRangeSlider from 'react-time-range-slider'
import styles from './styles.module.scss'
import { observable, computed } from 'mobx'
import types from 'prop-types'
import get from 'lodash/get'
import DropdownSelect from '../../molecules/DropdownSelect'
import deleteIcon from '../../../assets/icons/delete.svg'
import Icon from '../../atoms/Icon'
import cn from 'classnames'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import { SearchBox } from 'react-google-maps/lib/components/places/SearchBox'
import Location from './Location'
import { usersApi } from '../../../api/users.api'
import { commonApi } from '../../../api'
import { toast } from 'react-toastify'
import { getAddressObject } from '../../../helpers/getAdress';


const MapWithAMarker = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap defaultZoom={12} {...props}>
        <Marker position={props.position} />
      </GoogleMap>
    )
  })
)

@inject('modalsStore')
@observer
class EditModal extends Component {
  static propTypes = {
    closeCb: types.func,
    big: types.bool
  }

  static defaultProps = {
    closeCb: () => {}
  }

  @observable inProgress = false

  constructor(props) {
    super(props)
    this.initialState = {
      id: '',
      fullName: '',
      email: '',
      title: '',
      description: '',
      regCategory: '',
      subCategory: '',
      categoryId: '',
      subCategoryId: '',
      availability: [],
      country: '',
      city: '',
      street: '',
      address: '',
      status: '',
      location: {
        lat: 0,
        lng: 0
      },
      time: {
        start: '00:00',
        end: '23:59'
      },
      errors: {
        fullName: false,
        email: false,
        title: false,
        description: false,
        country: false,
        city: false,
        street: false,
        status: ''
      },
      isShowMap: false,
      subCategoryList: []
    }
    this.statuses = [
      {
        label: 'Completed',
        value: 'Completed'
      },
      {
        label: 'Expired',
        value: 'Expired'
      },
      {
        label: 'Rejected',
        value: 'Rejected'
      },
      {
        label: 'Canceled',
        value: 'Canceled'
      },
      {
        label: 'Applied',
        value: 'Applied'
      },
      {
        label: 'In progress',
        value: 'In progress'
      },
      {
        label: 'Coming up',
        value: 'Coming up'
      }
    ]
    this.state = this.initialState
  }

  inviteClient = e => {
    e.preventDefault()
    const {
      id,
      title,
      description,
      street,
      city,
      country,
      availability,
      location,
      subCategoryId,
      time
    } = this.state
    const availabilityData = availability.reduce(function (accumulator, currentValue) {
      accumulator[currentValue[0]] = currentValue[1]
      return accumulator
    }, {})
    const availabilityDataFull = {
      time_from: time.start,
      time_to: time.end,
      ...availabilityData
    }
    this.inProgress = true
    const data = {
      title,
      description,
      interest: subCategoryId,
      availability: availabilityDataFull,
      street,
      city,
      country,
      location: {
        latitude: location.lat,
        longitude: location.lng
      }
    }

    usersApi
      .editHelper(id, data)
      .then(response => {
        toast.success('Help Ad was edited successfully')
        this.props.modalsStore.closeEditAdsModal()
        this.props.modalsStore.getWorksList()
        this.props.closeCb()
      })
      .catch(error => {
        let errorMessage = 'Error when trying to change helper user'
        toast.error(errorMessage)
      })
      .finally(() => {
        // this.clearFields()
      })
  }

  handleTextInputChange = type => data => {
    this.setState({
      [type]: data.value,
      errors: { [type]: data.hasError }
    })
  }

  handleShowMap = () => {
    this.setState({
      isShowMap: !this.state.isShowMap
    })
  }

  handleChangeSelect = data => {
    this.setState({
      subCategory: data.label,
      subCategoryId: data.id
    })
  }

  handleChangeCategorySelect = data => {
    this.setState({
      regCategory: data.label,
      categoryId: data.value,
      subCategory: ''
    })
  }

  handleChangeTime = value => {
    this.setState({
      time: value
    })
  }

  handleDeleteItem = value => () => {
    const newavAilability = this.state.availability.map(availability => {
      if (availability[0] === value) {
        return [value, false]
      }
      return availability
    })
    this.setState({
      availability: newavAilability
    })
  }

  handleAddItem = value => () => {
    const newavAilability = this.state.availability.map(availability => {
      if (availability[0] === value) {
        return [value, true]
      }
      return availability
    })
    this.setState({
      availability: newavAilability
    })
  }

  handleChangeLocation = props => {

    this.setState({
      location: {
        lat: props.latLng.lat(),
        lng: props.latLng.lng()
      }
    })
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${props.latLng.lat()},${props.latLng.lng()}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places&language=en`
    )
      .then(response => {
        return response.json()
      })
      .then(data => {
        const adress = getAddressObject(data.results[0].address_components)
        this.setState({
          address: `${get(adress, 'street', '')},${get(adress, 'home', '')} ${get(
            adress,
            'city',
            ''
          )} ${get(adress, 'postal_code', '')} ${get(adress, 'country', '')} `,
          street: `${get(adress, 'street', '')},${get(adress, 'home', '')}`,
          city: `${get(
            adress,
            'city',
            ''
          )} ${get(adress, 'postal_code', '')}`,
          country: get(adress, 'country', ''),
        })
      })
  }

  handleChangeLocationMap = (lat, lng, address_components) => {
    const adress = getAddressObject(address_components)
    this.setState({
      location: {
        lat, lng,
      },
      address: `${get(adress, 'street', '')},${get(adress, 'home', '')} ${get(
        adress,
        'city',
        ''
      )} ${get(adress, 'postal_code', '')} ${get(adress, 'country', '')} `,
      street: `${get(adress, 'street', '')},${get(adress, 'home', '')}`,
      city: `${get(
        adress,
        'city',
        ''
      )} ${get(adress, 'postal_code', '')}`,
      country: get(adress, 'country', ''),
    })
  }

  closeClick = () => {
    this.props.modalsStore.closeEditAdsModal()
    this.setState(this.initialState)
  }

  @computed get isSubmitBlocked() {
    return this.inProgress
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      modalsStore: { editFormField }
    } = nextProps

    if (nextProps.modalsStore.editFormField.id !== prevState.id) {
      const { time_from, time_to, ...rest } = get(editFormField, 'availability', {})
      const availabilitiesValues = Object.entries(rest)
      return {
        id: get(editFormField, 'id', ''),
        fullName: get(editFormField, 'creator.full_name', ''),
        email: get(editFormField, 'creator.email', ''),
        title: get(editFormField, 'title', ''),
        subCategory: get(editFormField, 'interest.label', ''),
        categoryId: get(editFormField, 'interest.category', ''),
        regCategory: get(editFormField, 'interest.category_label', ''),
        description: get(editFormField, 'description', ''),
        country: get(editFormField, 'country', ''),
        city: get(editFormField, 'city', ''),
        street: get(editFormField, 'street', ''),
        status: get(editFormField, 'status', ''),
        address: `${get(
          editFormField,
          'street',
          ''
        )} ${get(editFormField, 'city', '')} ${get(editFormField, 'country', '')}  `,
        availability: availabilitiesValues,
        subCategoryId: get(editFormField, 'interest.id', ''),
        time: {
          start: time_from && time_from.substring(0, 5),
          end: time_to && time_to.substring(0, 5)
        },
        location: {
          lat: get(editFormField, 'location.coordinates', [])[1],
          lng: get(editFormField, 'location.coordinates', [])[0]
        }
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.categoryId !== prevState.categoryId) {
      commonApi
        .getSubCategories(this.state.categoryId)
        .then(response => {
          this.setState({
            subCategoryList: response
          })
        })
        .catch(error => {
          toast.error('Error when  to get categories')
        })
    }
  }

  render() {
    return (
      <Modal big={this.props.big} open={this.props.modalsStore.showEditAdsModal}>
        <div className={styles['modal-invite']}>
          <h4>Edit Help</h4>
          <form onSubmit={this.inviteClient}>
            <div className={styles['modal-invite__input']}>
              <Input
                label="title"
                type="text"
                handleChange={this.handleTextInputChange('title')}
                hasError={this.state.errors.title}
                value={this.state.title}
                withTooltip
                required
              />
            </div>
            <div className={styles['modal-invite__input']}>
              <Input
                label="description"
                type="text"
                handleChange={this.handleTextInputChange('description')}
                hasError={this.state.errors.description}
                value={this.state.description}
                withTooltip
                required
              />
            </div>
            <Location
              handleShowMap={this.handleShowMap}
              handleChangeLocationMap={this.handleChangeLocationMap}
              value={this.state.address}
            />
            {this.state.isShowMap && (
              <div className={styles['modal-invite__map']}>
                <MapWithAMarker
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places&language=en`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  center={this.state.location}
                  position={this.state.location}
                  onClick={this.handleChangeLocation}
                />
              </div>
            )}
            <div className={styles['modal-invite__select-wrapper']}>
              <DropdownSelect
                label="Category"
                value={this.state.regCategory}
                options={this.props.modalsStore.editFormRegCategories}
                onSelect={this.handleChangeCategorySelect}
              />
              <DropdownSelect
                label="Subcategory"
                value={this.state.subCategory}
                options={this.state.subCategoryList}
                onSelect={this.handleChangeSelect}
              />
            </div>
            <div className={styles['modal-invite__availability-wrapper']}>
              <label>Availabilities</label>
              <div className={styles['modal-invite__availabilities']}>
                {this.state.availability.map((item, key) => {
                  if (item[1]) {
                    return (
                      <div
                        key={key}
                        onClick={this.handleDeleteItem(item[0])}
                        className={styles['modal-invite__availability']}
                      >
                        <span>
                          <Icon svg={deleteIcon} colorStroke="red" colorFill="red" />
                        </span>
                        {item[0]} {this.state.time.start} - {this.state.time.end}
                      </div>
                    )
                  }
                  return (
                    <div
                      key={key}
                      onClick={this.handleAddItem(item[0])}
                      className={cn(
                        styles['modal-invite__availability--dark'],
                        styles['modal-invite__availability']
                      )}
                    >
                      <span>+</span>
                      {item[0]}
                    </div>
                  )
                })}
              </div>
              <TimeRangeSlider
                disabled={false}
                format={24}
                maxValue={'23:59'}
                minValue={'00:00'}
                name={'time_range'}
                onChange={this.handleChangeTime}
                step={10}
                value={this.state.time}
              />
              <div className={styles['modal-invite__ruler']}>
                <div className={styles['modal-invite__ruler__line']} />

                <div className={styles['modal-invite__ruler__block']}>
                  <span>0</span>
                  <span>2</span>
                  <span>4</span>
                  <span>6</span>
                  <span>8</span>
                  <span>10</span>
                  <span>12</span>
                  <span>14</span>
                  <span>16</span>
                  <span>18</span>
                  <span>20</span>
                  <span>22</span>
                  <span>24</span>
                </div>
              </div>
            </div>
          </form>
          <div className={styles['modal-invite__buttons']}>
            <div>
              <Button text="Cancel" type="button" onClick={this.closeClick} isGray />
            </div>
            <div>
              <Button text="Сonfirm editing" type="button" onClick={this.inviteClient} />
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default EditModal
