import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import types from 'prop-types'

Card.propTypes = {
  header: types.string,
  noPad: types.bool
}

export default function Card(props) {
  return (
    <div className={styles['card__wrapper']}>
      {props.header &&
        <h4>{props.header}</h4>
      }
      <div className={classNames(styles['card'],
        props.noPad && styles['card-noPad'])}>
        {props.children}
      </div>
    </div>
  )
}
