import history from '../routes/history'

export const getQueryParam = (param, defaultData) => {
  const query = history.location.search.substring(1)
  const firstQuery = query.split('/')
  const vars = firstQuery[0].split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) === param) {
      let result = decodeURIComponent(pair[1]).replace(/\+/g, ' ')
      if (result) {
        return result.includes(',') ?
          result.split(',') : result
      } else {
        return defaultData
      }
    }
  }
  return defaultData
}

export const addQueryParam = (paramName, param) => {
  if (Array.isArray(param)) {
    param = param.join(',')
  }
  const query = new URLSearchParams(history.location.search)
  query.set(paramName, param)
  history.replace({...history.location, search: query.toString()})
}

export const removeQueryParam = (paramName) => {
  const query = new URLSearchParams(history.location.search)
  query.delete(paramName)
  history.replace({...history.location, search: query.toString()})
}
