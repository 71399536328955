import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Tabs from '../../atoms/Tabs'
import PageWrap from '../../prototypes/PageWrap'
import UserInfo from '../../prototypes/UserInfo'
import UserRating from '../../prototypes/UserRating'
import PreloaderPage from '../../atoms/PreloaderPage'
import { observer, inject } from 'mobx-react'
import { observable } from 'mobx'
import { usersApi } from '../../../api'
import moment from 'moment'
import { toast } from 'react-toastify'
import { addQueryParam, getQueryParam } from '../../../helpers/history'


@withRouter
@inject('modalsStore')
@observer
class UserDetails extends Component {

  @observable activeTab = ''
  @observable userId
  @observable userName = ''
  @observable isUserActive
  @observable userInfoLoaded = false

  @observable userInfo = {}
  @observable userContacts = {}
  @observable userActivity = {}
  @observable userIncomes = {}

  constructor(props) {
    super(props)
    this.activeTab = getQueryParam('tab', 'Client Information')
    this.userId = getQueryParam('id')
    if (!this.userId) {
      this.props.history.replace('/users')
    } else {
      this.loadData()
    }
  }

  changeTab = tabName => {
    addQueryParam('tab', tabName)
  }

  loadData = () => {
    usersApi.getUser(this.userId)
      .then(response => {
        this.isUserActive = response.is_active
        this.userName = response.full_name
        this.userInfo = {
          'Full Name': response.full_name,
          'Date of Birth': response.date_of_birth ?
            moment(response.date_of_birth, 'YYYY-MM-DD').format('DD.MM.YYYY') : undefined,
          'Profession': response.profession,
          'Employer rating': `${response.employer_rate} / 5.0`,
          'Helper rating': `${response.employee_rate} / 5.0`
        }
        this.userContacts = {
          'Email': response.email
        }
        this.userActivity = {
          'Jobs created': response.jobs_created,
          'Jobs accepted': response.jobs_accepted
        }
        this.userIncomes = {
          earned: response.money_earned,
          spent: response.money_spent
        }
        this.userInfoLoaded = true
      })
      .catch(error => {
        toast.error('Error when trying to get user data')
      })
  }

  deactivateUser = () => {
    this.props.modalsStore.openConfirmModal(
      'Deactivate',
      `Are you sure you want to deactivate ${this.userName} account?`,
      () => {
        usersApi.deactivateUser(this.userId)
          .then(response => {
            this.isUserActive = false
            toast.success('User was deactivated successfully')
          })
          .catch(error => {
            toast.error('Error when trying to deactivate user')
          })
      }
    )
  }

  activateUser = () => {
    usersApi.activateUser(this.userId)
      .then(response => {
        this.isUserActive = true
        toast.success('User was activated')
      })
      .catch(error => {
        toast.error('Error when trying to activate user')
      })
  }

  deleteUser = () => {
    this.props.modalsStore.openConfirmModal(
      'Deletion',
      `Are you sure you want to delete ${this.userName} account?`,
      () => {
        usersApi.deleteUser(this.userId)
          .then(response => {
            toast.success('User was deleted successfully')
            this.props.history.replace('/users')
          })
          .catch(error => {
            toast.error('Error when trying to delete user')
          })
      }
    )
  }

  render() {
    return (
      <>
        {this.userInfoLoaded ?
          <PageWrap
            heading={this.userName}
            backBtnText='All clients'
            buttonText={this.isUserActive === true ? 'Deactivate' : 'Delete'}
            onBtnClick={this.isUserActive === true ? this.deactivateUser : this.deleteUser}
            secondBtnText='Activate'
            onSecondBtnClick={this.activateUser}
            hasSecondBtn={this.isUserActive === false}
            hasBtn
            hasBackBtn
          >
            <Tabs
              activeTab={this.activeTab}
              tabNames={['Client Information', 'Rating']}
              onTabChange={this.changeTab}
            >
              <UserInfo
                userInfo={this.userInfo}
                userContacts={this.userContacts}
                userActivity={this.userActivity}
                userIncomes={this.userIncomes}
              />
              <UserRating
                userId={this.userId}
                onRatingDelete={this.loadData}
              />
            </Tabs>
          </PageWrap>
          :
          <div style={{height: '100vh', width: '100%'}}>
            <PreloaderPage/>
          </div>
        }
      </>
    )
  }

}

export default UserDetails
