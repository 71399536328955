import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { observable, computed } from 'mobx'
import Input from '../../../components/molecules/Input'
import Button from '../../molecules/Button'
import styles from './styles.module.scss'
import { authApi } from '../../../api/auth.api'
import { toast } from 'react-toastify'
import { getQueryParam, removeQueryParam } from '../../../helpers/history'
import logo from '../../../assets/images/logo.png'


@withRouter
@inject('commonStore')
@observer
class Login extends Component {

  @observable inProgress = false
  @observable email = ''
  @observable password = ''
  @observable errors = [true, true]
  @observable justSubmit = false

  constructor(props) {
    super(props)
    this.emailInputRef = React.createRef()
    this.passwordInputRef = React.createRef()
    const authToken = getQueryParam('token')
    if (authToken) {
      this.props.commonStore.showPreloader()
      authApi.loginConfirm(authToken)
        .then(response => {
          this.props.commonStore.authorize(response)
        })
        .catch(error => {
          removeQueryParam('token')
          if (error.response) {
            toast.error(error.response.data.token[0])
          } else {
            toast.error('Error when trying to authenticate')
          }
        })
        .finally(() => {
          this.props.commonStore.hidePreloader()
        })
    }
  }

  handleEmailChange = data => {
    this.errors[0] = data.hasError
    this.email = data.value
  }

  handlePasswordChange = data => {
    this.errors[1] = data.hasError
    this.password = data.value
  }

  submitLogin = e => {
    e.preventDefault()
    this.inProgress = true
    authApi.login(this.email, this.password)
      .then(response => {
        toast.success('Please check your email for authorization link')
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data.non_field_errors[0])
        } else {
          toast.error('Error during login')
        }
      })
      .finally(() => {
        this.email = ''
        this.password = ''
        this.errors = [true, true]
        this.emailInputRef.current.refreshInput()
        this.passwordInputRef.current.refreshInput()
        this.inProgress = false
      })
  }

  @computed get submitBlocked() {
    return this.inProgress || this.errors.includes(true)
  }

  render() {
    return(
      <div className={styles['login']}>
        <img
          className={styles['login__logo']}
          src={logo}
          alt=""
        />
        <h1>
          COLLABRI Admin
        </h1>
        <form className={styles['form']} onSubmit={this.submitLogin}>
          <div className={styles['form__input']}>
            <Input
              ref={this.emailInputRef}
              label="email"
              type="email"
              handleChange={this.handleEmailChange}
              hasError={this.errors[0]}
              value={this.email}
              withTooltip
              required
            />
          </div>
          <div className={styles['form__input']}>
            <Input
              ref={this.passwordInputRef}
              label="password"
              type="password"
              handleChange={this.handlePasswordChange}
              hasError={this.errors[1]}
              value={this.password}
              withTooltip
              required
            />
          </div>
          <div className={styles['form__btn']}>
            <Button
              text="Log In"
              type="submit"
              disabled={this.submitBlocked}
            />
          </div>
        </form>
      </div>
    )
  }

}

export default Login
