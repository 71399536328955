exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../assets/fonts/neusa-next-std.otf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../assets/fonts/cabin/Cabin-Regular.otf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../assets/fonts/cabin/Cabin-SemiBold.otf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../assets/fonts/cabin/Cabin-Bold.otf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("../assets/fonts/Nunito/Nunito-Light.ttf"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("../assets/fonts/Nunito/Nunito-Regular.ttf"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("../assets/fonts/Nunito/Nunito-SemiBold.ttf"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("../assets/fonts/Nunito/Nunito-Bold.ttf"));

// Module
exports.push([module.id, "@font-face {\n  font-family: Neusa-next;\n  src: url(" + ___CSS_LOADER_URL___0___ + ") format(\"opentype\");\n  font-weight: 300; }\n\n@font-face {\n  font-family: Cabin;\n  src: url(" + ___CSS_LOADER_URL___1___ + ") format(\"opentype\");\n  font-weight: 400; }\n\n@font-face {\n  font-family: Cabin;\n  src: url(" + ___CSS_LOADER_URL___2___ + ") format(\"opentype\");\n  font-weight: 600; }\n\n@font-face {\n  font-family: Cabin;\n  src: url(" + ___CSS_LOADER_URL___3___ + ") format(\"opentype\");\n  font-weight: 700; }\n\n@font-face {\n  font-family: \"Nunito Sans\";\n  src: url(" + ___CSS_LOADER_URL___4___ + ") format(\"truetype\");\n  font-weight: 300; }\n\n@font-face {\n  font-family: \"Nunito Sans\";\n  src: url(" + ___CSS_LOADER_URL___5___ + ") format(\"truetype\");\n  font-weight: 400; }\n\n@font-face {\n  font-family: \"Nunito Sans\";\n  src: url(" + ___CSS_LOADER_URL___6___ + ") format(\"truetype\");\n  font-weight: 600; }\n\n@font-face {\n  font-family: \"Nunito Sans\";\n  src: url(" + ___CSS_LOADER_URL___7___ + ") format(\"truetype\");\n  font-weight: 700; }\n\nhtml,\nbody,\nform,\nfieldset,\ntable,\ntr,\ntd,\nimg {\n  font-family: \"Nunito Sans\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale; }\n\nbody {\n  min-width: 1280px;\n  margin: 0;\n  background: #F5F6F7; }\n  body.overflow-hidden {\n    overflow: hidden !important;\n    position: fixed; }\n\ninput,\nbutton,\nselect,\ntextarea,\noptgroup,\noption {\n  font-family: inherit; }\n\nimg {\n  -webkit-user-select: none;\n          user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none; }\n\nh1,\nh2,\nh3,\nh4,\nh5 {\n  font-family: Cabin, sans-serif;\n  -webkit-user-select: none;\n          user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none; }\n\nmain.main {\n  display: flex; }\n  main.main__noSidebar {\n    display: block;\n    background: #FFFFFF;\n    min-height: 100vh; }\n", ""]);

