import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import commonStore from '../store/common.store';

export default function LoginRoute({ component: Component, ...rest }) {
  console.log(process.env.REACT_APP_GOOGLE_API_KEY,"process.env.REACT_APP_GOOGLE_API_KEY");
  return (
    <Route {...rest} render={(props) => (
      commonStore.tokenPair ?
        <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }}/>
        :
        <Component {...props} />
    )}/>
  )
}
