import React, { Component } from 'react'
import Modal from '../../prototypes/Modal'
import Button from '../../molecules/Button'
import Input from '../../../components/molecules/Input'
import { toast } from 'react-toastify'
import { usersApi } from '../../../api/users.api'
import { observer, inject } from 'mobx-react'
import styles from './styles.module.scss'
import { observable, computed } from 'mobx'
import types from 'prop-types'


@inject('modalsStore')
@observer
class InviteModal extends Component {

  static propTypes = {
    closeCb: types.func
  }

  static defaultProps = {
    closeCb: () => {}
  }

  @observable inProgress = false

  @observable fullName = ''
  @observable email = ''
  @observable errors = [true, true]

  constructor(props) {
    super(props)
    this.emailInputRef = React.createRef()
    this.fullNameInputRef = React.createRef()
  }

  inviteClient = e => {
    e.preventDefault()
    this.inProgress = true
    usersApi.inviteUser(this.fullName, this.email)
      .then(response => {
        toast.success('User was invited successfully')
        this.props.modalsStore.closeInviteModal()
        this.props.closeCb()
      })
      .catch(error => {
        let errorMessage = 'Error when trying to invite user'
        if (error.response.data) {
          errorMessage = error.response.data.email[0]
        }
        toast.error(errorMessage)
      })
      .finally(() => {
        this.clearFields()
      })
  }

  handleFullNameChange = data => {
    this.errors[1] = data.hasError
    this.fullName = data.value
  }

  handleEmailChange = data => {
    this.errors[0] = data.hasError
    this.email = data.value
  }

  closeClick = () => {
    this.props.modalsStore.closeInviteModal()
    this.clearFields()
  }

  clearFields = () => {
    this.email = ''
    this.fullName = ''
    this.errors = [true, true]
    this.fullNameInputRef.current.refreshInput()
    this.emailInputRef.current.refreshInput()
    this.inProgress = false
  }

  @computed get isSubmitBlocked() {
    return this.inProgress || this.errors.includes(true)
  }

  render() {
    return (
      <Modal
        open={this.props.modalsStore.showInviteModal}
      >
        <div className={styles['modal-invite']}>
          <h4>Invite Client</h4>
          <form onSubmit={this.inviteClient}>
            <div className={styles['modal-invite__input']}>
              <Input
                ref={this.emailInputRef}
                label="email"
                type="email"
                handleChange={this.handleEmailChange}
                hasError={this.errors[0]}
                value={this.email}
                withTooltip
                required
              />
            </div>
            <div className={styles['modal-invite__input']}>
              <Input
                ref={this.fullNameInputRef}
                label="name"
                type="text"
                handleChange={this.handleFullNameChange}
                hasError={this.errors[1]}
                value={this.fullName}
                withTooltip
                required
              />
              <div className={styles['modal-invite__input-info']}>
                <p>
                  Used for email salutations only. Example: if your input is "Peter" the salutation will be "Dear Peter".
                </p>
              </div>
            </div>
          </form>
          <div className={styles['modal-invite__buttons']}>
            <div>
              <Button
                text="Cancel"
                type="button"
                onClick={this.closeClick}
                isGray
              />
            </div>
            <div>
              <Button
                text="Send invitation"
                type="button"
                disabled={this.isSubmitBlocked}
                onClick={this.inviteClient}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  }

}

export default InviteModal
