import React from 'react'
import ReactDOM from 'react-dom'
import './sass/styles.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'mobx-react'
import { Router } from 'react-router-dom'
import history from './routes/history'

import commonStore from './store/common.store'
import modalsStore from './store/modals.store'

const stores = {
  commonStore,
  modalsStore
}

ReactDOM.render((
  <Provider {...stores}>
    <Router history={history}>
      <App/>
    </Router>
  </Provider>
), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
