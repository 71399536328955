import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import commonStore from '../store/common.store';

export default function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest} render={(props) => (
      commonStore.tokenPair ?
        <Component {...props} />
        :
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }}/>
    )}/>
  )
}
