exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_login__2iHMj {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-top: 16vh; }\n  .styles_login__2iHMj h1 {\n    font-size: 72px;\n    font-weight: 300;\n    margin: 46px 0;\n    -webkit-user-select: none;\n            user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    font-family: Neusa-next, sans-serif; }\n  .styles_login__2iHMj .styles_form__uDFky {\n    width: 100%;\n    max-width: 400px;\n    min-width: 300px; }\n    .styles_login__2iHMj .styles_form__input__3ZHik {\n      margin: 20px 0; }\n    .styles_login__2iHMj .styles_form__btn__1SG6X {\n      margin-top: 55px; }\n  .styles_login__logo__1Jzwh {\n    width: 175px;\n    padding: 42px;\n    position: absolute;\n    left: 0;\n    top: 0; }\n", ""]);

// Exports
exports.locals = {
	"login": "styles_login__2iHMj",
	"form": "styles_form__uDFky",
	"form__input": "styles_form__input__3ZHik",
	"form__btn": "styles_form__btn__1SG6X",
	"login__logo": "styles_login__logo__1Jzwh"
};