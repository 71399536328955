exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_tabs__3O9kk {\n  border-bottom: 1px solid #EBEBEB;\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 30px; }\n  .styles_tabs__wrapper__3VLE7 {\n    display: flex;\n    flex-direction: column; }\n  .styles_tabs__tab__27K8R {\n    flex: 1 1;\n    max-width: 235px;\n    position: relative;\n    cursor: pointer; }\n    .styles_tabs__tab__27K8R a {\n      text-decoration: none; }\n    .styles_tabs__tab__27K8R:hover {\n      background: #EBEBEB; }\n  .styles_tabs__text__e-0_4 {\n    width: 100%;\n    padding: 12px 0;\n    text-align: center;\n    margin: 0;\n    font-weight: 600;\n    color: #000000; }\n  .styles_tabs__active__16LJw {\n    position: absolute;\n    width: 100%;\n    height: 3px;\n    background-color: #FF3E5F;\n    bottom: 0;\n    border-radius: 100px 100px 0 0; }\n  .styles_tabs__content__1NvaW {\n    display: none; }\n    .styles_tabs__content-active__2fzB3 {\n      display: block; }\n", ""]);

// Exports
exports.locals = {
	"tabs": "styles_tabs__3O9kk",
	"tabs__wrapper": "styles_tabs__wrapper__3VLE7",
	"tabs__tab": "styles_tabs__tab__27K8R",
	"tabs__text": "styles_tabs__text__e-0_4",
	"tabs__active": "styles_tabs__active__16LJw",
	"tabs__content": "styles_tabs__content__1NvaW",
	"tabs__content-active": "styles_tabs__content-active__2fzB3"
};