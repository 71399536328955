import React, { Component } from 'react'
import Modal from '../../prototypes/Modal'
import Button from '../../molecules/Button'
import Input from '../../../components/molecules/Input'
import { observer, inject } from 'mobx-react'
import styles from './styles.module.scss'
import { observable, computed } from 'mobx'
import types from 'prop-types'
import get from 'lodash/get'
import DropdownSelect from '../../molecules/DropdownSelect'
import editIcon from '../../../assets/icons/delete.svg'
import Icon from '../../atoms/Icon'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import Location from '../EditAdsModal/Location'
import { commonApi, usersApi } from '../../../api'
import { toast } from 'react-toastify'
import moment from 'moment'
import 'moment-timezone'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { getAddressObject } from '../../../helpers/getAdress'
import { utcToZonedTime } from 'date-fns-tz'

const MapWithAMarker = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap defaultZoom={12} {...props}>
        <Marker position={props.position} />
      </GoogleMap>
    )
  })
)

@inject('modalsStore')
@observer
class WorksModal extends Component {
  static propTypes = {
    closeCb: types.func,
    big: types.bool
  }

  static defaultProps = {
    closeCb: () => {}
  }

  @observable inProgress = false

  constructor(props) {
    super(props)
    this.initialState = {
      id: '',
      fullName: '',
      email: '',
      title: '',
      description: '',
      regCategory: '',
      subCategory: '',
      categoryId: '',
      subCategoryId: '',
      work_dates: [],
      country: '',
      city: '',
      street: '',
      address: '',
      status: '',
      timezone: '',
      location: {
        lat: 0,
        lng: 0
      },
      time: {
        start: '00:00',
        end: '23:59'
      },
      errors: {
        fullName: false,
        email: false,
        title: false,
        description: false,
        country: false,
        city: false,
        street: false,
        status: ''
      },
      isShowMap: false,
      subCategoryList: []
    }
    this.statuses = [
      {
        label: 'Completed',
        value: 'Completed'
      },
      {
        label: 'Expired',
        value: 'Expired'
      },
      {
        label: 'Rejected',
        value: 'Rejected'
      },
      {
        label: 'Canceled',
        value: 'Canceled'
      },
      {
        label: 'Applied',
        value: 'Applied'
      },
      {
        label: 'In progress',
        value: 'In progress'
      },
      {
        label: 'Coming up',
        value: 'Coming up'
      }
    ]
    this.state = this.initialState
  }

  inviteClient = e => {
    e.preventDefault()
    const {
      id,
      title,
      description,
      street,
      city,
      country,
      work_dates,
      location,
      subCategoryId
    } = this.state
    this.inProgress = true

    const unitedSelectedDate = work_dates.map(item => {
      const setCurrentDate = {
        date: moment(item.date).get('date'),
        month: moment(item.date).get('month'),
        year: moment(item.date).get('year')
      }
      return {
        ...item,
        time_from: moment(item.time_from)
          .set(setCurrentDate)
          .tz(
            this.state.timezone ? this.state.timezone : 'Atlantic/Reykjavik',
            item.isChanged ? true : false
          )

          .format(),
        time_to: moment(item.time_to)
          .set(setCurrentDate)
          .tz(
            this.state.timezone ? this.state.timezone : 'Atlantic/Reykjavik',
            item.isChanged ? true : false
          )
          .format()
      }
    })

    const data = {
      title,
      description,
      interest: subCategoryId,
      work_dates: unitedSelectedDate,
      street,
      city,
      country,
      location: {
        latitude: location.lat,
        longitude: location.lng
      }
    }

    usersApi
      .editWarker(id, data)
      .then(response => {
        toast.success('Work Ad was edited successfully')
        this.props.modalsStore.closeWorksAdsModal()
        this.props.modalsStore.getWorksList()
        this.props.closeCb()
      })
      .catch(error => {
        let errorMessage = 'Error when trying to change helper user'
        toast.error(errorMessage)
      })
      .finally(() => {
        // this.clearFields()
      })
  }

  handleTextInputChange = type => data => {
    this.setState({
      [type]: data.value,
      errors: { [type]: data.hasError }
    })
  }

  handleShowMap = () => {
    this.setState({
      isShowMap: !this.state.isShowMap
    })
  }

  handleChangeSelect = data => {
    this.setState({
      subCategory: data.label,
      subCategoryId: data.id
    })
  }

  handleChangeCategorySelect = data => {
    this.setState({
      regCategory: data.label,
      categoryId: data.value,
      subCategory: ''
    })
  }

  handleChangeTime = value => {
    this.setState({
      time: value
    })
  }

  handleDeleteItem = index => () => {
    const newArray = [...this.state.work_dates]
    newArray.splice(index, 1)
    this.setState({
      work_dates: newArray
    })
  }

  handleAddItem = () => {
    this.setState({
      work_dates: [
        ...this.state.work_dates,
        {
          date: moment(new Date()).format('YYYY-MM-DD'),
          time_from: moment().toISOString(),
          time_to: moment().toISOString()
        }
      ]
    })
  }

  handleChangeLocation = props => {
    this.setState({
      location: {
        lat: props.latLng.lat(),
        lng: props.latLng.lng()
      }
    })
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${props.latLng.lat()},${props.latLng.lng()}&key=AIzaSyC5sBh1VfVNJS2CkXmTLvv_vJD8vHJJILo&language=en`
    )
      .then(response => {
        return response.json()
      })
      .then(data => {
        const adress = getAddressObject(data.results[0].address_components)
        this.setState({
          address: `${get(adress, 'street', '')},${get(adress, 'home', '')} ${get(
            adress,
            'city',
            ''
          )} ${get(adress, 'postal_code', '')} ${get(adress, 'country', '')} `,
          street: `${get(adress, 'street', '')},${get(adress, 'home', '')}`,
          city: `${get(adress, 'city', '')} ${get(adress, 'postal_code', '')}`,
          country: get(adress, 'country', '')
        })
      })
  }

  handleChangeLocationMap = (lat, lng, address_components) => {
    const adress = getAddressObject(address_components)
    this.setState({
      location: {
        lat,
        lng
      },
      address: `${get(adress, 'street', '')},${get(adress, 'home', '')} ${get(
        adress,
        'city',
        ''
      )} ${get(adress, 'postal_code', '')} ${get(adress, 'country', '')} `,
      street: `${get(adress, 'street', '')},${get(adress, 'home', '')}`,
      city: `${get(adress, 'city', '')} ${get(adress, 'postal_code', '')}`,
      country: get(adress, 'country', '')
    })
  }

  handleChangeDate = index => date => {
    const newArray = [...this.state.work_dates]
    newArray.splice(index, 1, {
      ...this.state.work_dates[index],
      date: moment(date).format('YYYY-MM-DD')
    })
    this.setState({
      work_dates: newArray
    })
  }

  handleChangeTime = (index, key) => date => {
    const newArray = [...this.state.work_dates]
    newArray.splice(index, 1, {
      ...this.state.work_dates[index],
      [key]: date,
      isChanged: true
    })

    this.setState({
      work_dates: newArray
    })
  }

  closeClick = () => {
    this.props.modalsStore.closeWorksAdsModal()
    this.setState(this.initialState)
  }

  @computed get isSubmitBlocked() {
    return this.inProgress
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      modalsStore: { editFormField }
    } = nextProps

    if (nextProps.modalsStore.editFormField.id !== prevState.id) {
      const { time_from, time_to, ...rest } = get(editFormField, 'availability', {})
      const availabilitiesValues = Object.entries(rest)

      return {
        id: get(editFormField, 'id', ''),
        fullName: get(editFormField, 'creator.full_name', ''),
        email: get(editFormField, 'creator.email', ''),
        title: get(editFormField, 'title', ''),
        subCategory: get(editFormField, 'interest.label', ''),
        categoryId: get(editFormField, 'interest.category', ''),
        regCategory: get(editFormField, 'category_title', ''),
        description: get(editFormField, 'description', ''),
        country: get(editFormField, 'country', ''),
        city: get(editFormField, 'city', ''),
        street: get(editFormField, 'street', ''),
        status: get(editFormField, 'status', ''),
        address: `${get(editFormField, 'street', '')} ${get(editFormField, 'city', '')} ${get(
          editFormField,
          'country',
          ''
        )}  `,
        subCategoryId: get(editFormField, 'interest.id', ''),
        work_dates: get(editFormField, 'work_dates', []).map(dateItem => ({
          ...dateItem,
          isChanged: false
        })),
        timezone: get(editFormField, 'time_zone', ''),
        time: {
          start: time_from && time_from.substring(0, 5),
          end: time_to && time_to.substring(0, 5)
        },
        location: {
          lat: get(editFormField, 'location.coordinates', [])[1],
          lng: get(editFormField, 'location.coordinates', [])[0]
        }
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.categoryId !== prevState.categoryId) {
      commonApi
        .getSubCategories(this.state.categoryId)
        .then(response => {
          this.setState({
            subCategoryList: response
          })
        })
        .catch(error => {
          toast.error('Error when  to get categories')
        })
    }
  }

  render() {
    return (
      <Modal big={this.props.big} open={this.props.modalsStore.showWorksAdsModal}>
        <div className={styles['modal-invite']}>
          <h4>Edit Works</h4>
          <form onSubmit={this.inviteClient}>
            <div className={styles['modal-invite__input']}>
              <Input
                label="title"
                type="text"
                handleChange={this.handleTextInputChange('title')}
                hasError={this.state.errors.title}
                value={this.state.title}
                withTooltip
                required
              />
            </div>
            <div className={styles['modal-invite__input']}>
              <Input
                label="description"
                type="text"
                handleChange={this.handleTextInputChange('description')}
                hasError={this.state.errors.description}
                value={this.state.description}
                withTooltip
                required
              />
            </div>
            <Location
              handleShowMap={this.handleShowMap}
              handleChangeLocationMap={this.handleChangeLocationMap}
              value={this.state.address}
            />
            {this.state.isShowMap && (
              <div className={styles['modal-invite__map']}>
                <MapWithAMarker
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places&language=en`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  center={this.state.location}
                  position={this.state.location}
                  onClick={this.handleChangeLocation}
                />
              </div>
            )}
            <div className={styles['modal-invite__select-wrapper']}>
              <DropdownSelect
                label="Category"
                value={this.state.regCategory}
                options={this.props.modalsStore.editFormRegCategories}
                onSelect={this.handleChangeCategorySelect}
              />
              <DropdownSelect
                label="Subcategory"
                value={this.state.subCategory}
                options={this.state.subCategoryList}
                onSelect={this.handleChangeSelect}
              />
            </div>
            <div className={styles['modal-invite__availability-wrapper']}>
              <label>Work dates</label>
              <div className={styles['modal-invite__availabilities']}>
                {this.state.work_dates.map((item, key) => {
                  const dayOfTheWeek = new Date(item.date)

                  return (
                    <div key={key} className={styles['modal-invite__availability']}>
                      <span onClick={this.handleDeleteItem(key)}>
                        <Icon svg={editIcon} colorStroke="red" colorFill="red" />
                      </span>
                      <div className={styles['modal-invite__date']}>
                        <DatePicker selected={dayOfTheWeek} onChange={this.handleChangeDate(key)} />
                        <div className={styles['modal-invite__time']}>
                          <DatePicker
                            selected={
                              item.isChanged
                                ? new Date(item.time_from)
                                : utcToZonedTime(
                                    item.time_from,
                                    this.state.timezone ? this.state.timezone : 'Atlantic/Reykjavik'
                                  )
                            }
                            onChange={this.handleChangeTime(key, 'time_from')}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={10}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                          <i>-</i>
                          <DatePicker
                            selected={
                              item.isChanged
                                ? new Date(item.time_to)
                                : utcToZonedTime(
                                    item.time_to,
                                    this.state.timezone ? this.state.timezone : 'Atlantic/Reykjavik'
                                  )
                            }
                            onChange={this.handleChangeTime(key, 'time_to')}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={10}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
                {this.state.work_dates.length < 3 && (
                  <div onClick={this.handleAddItem} className={styles['modal-invite__add-button']}>
                    +
                  </div>
                )}
              </div>
            </div>
          </form>
          <div className={styles['modal-invite__buttons']}>
            <div>
              <Button text="Cancel" type="button" onClick={this.closeClick} isGray />
            </div>
            <div>
              <Button text="Сonfirm editing" type="button" onClick={e => this.inviteClient(e)} />
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default WorksModal
