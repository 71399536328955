import React, { Component } from 'react'
import PageWrap from '../../prototypes/PageWrap'
import Card from '../../atoms/Card'
import Text from '../../atoms/Text'
import DropdownSelect from '../../molecules/DropdownSelect'
import Table from '../../organisms/Table'
import Pagination from '../../organisms/Pagination'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import SimpleDropdown from '../../molecules/SimpleDropdown'
import { toast } from 'react-toastify'
import { adsApi, commonApi } from '../../../api'
import { addQueryParam, getQueryParam, removeQueryParam } from '../../../helpers/history'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { computed, observable } from 'mobx'
import axios from '../../../api/axiosInstance'
import { CURRENCY_DICTIONARY } from '../../../constants'
import moment from 'moment'
import classNames from 'classnames'
import styles from './styles.module.scss'
import Icon from '../../atoms/Icon'
import editIcon from '../../../assets/icons/edit.svg'

@withRouter
@inject('modalsStore')
@observer
class AdDetails extends Component {
  @observable adData
  @observable adId
  @observable adType
  @observable dataIsLoading = false
  @observable fieldList = []
  @observable fieldListWork = []
  @observable categories = []
  @observable regularCategories = []
  @observable subcategory = ''
  @observable statusFilter = ''
  @observable scheduleFilter = ''
  @observable requestsData = []
  @observable isLoadingRequests = false
  @observable limit = 10
  @observable total = 0
  @observable page = 0
  @observable ordering = ''
  @observable calendarOpen = false
  @observable isFetching = false

  state = {
    dateSchedule: [new Date(), new Date()]
  }

  constructor(props) {
    super(props)
    this.adId = getQueryParam('id')
    this.adType = getQueryParam('type')
    this.page = getQueryParam('reqP', 1)
    this.statusFilter = getQueryParam('reqS', 'All')
    this.scheduleFilter = getQueryParam('reqC', 'All')
    this.ordering = getQueryParam('reqO', '')
    this.loadData()
    this.loadDataWork()
    if (!this.adId || !this.adType) {
      this.props.history.replace('/')
    } else {
      this.loadAdData()
    }
    commonApi
      .getCategories()
      .then(response => {
        this.categories.push({ label: 'All' })
        this.categories.push(
          ...response.map(item => {
            return {
              label: item.label === 'Other' ? `Others / ${item.category_label}` : item.label,
              value: item.id
            }
          })
        )
      })
      .catch(error => {
        toast.error('Error when trying to get categories')
      })
    commonApi
      .getRegularCategories()
      .then(response => {
        this.regularCategories.push(
          ...response.map(item => {
            return {
              label: item.label,
              value: item.id
            }
          })
        )
      })
      .catch(error => {
        toast.error('Error when trying to get categories')
      })
  }

  loadAdData = () => {
    this.dataIsLoading = true
    if (this.adType === 'Help') {
      this.getHelpAd()
    } else {
      this.getWorkAd()
    }
  }

  getHelpAd = () => {
    adsApi
      .getSingleHelpAd(this.adId)
      .then(response => {
        this.adData = response
        this.loadRequests()
      })
      .catch(error => {
        toast.error('Error when trying to get Ad Info')
      })
      .finally(() => {
        this.dataIsLoading = false
      })
  }

  getWorkAd = () => {
    adsApi
      .getSingleWorkAd(this.adId)
      .then(response => {
        this.adData = response
        this.loadRequests()
      })
      .catch(error => {
        toast.error('Error when trying to get Ad Info')
      })
      .finally(() => {
        this.dataIsLoading = false
      })
  }

  changeStatusFilter = status => {
    this.statusFilter = status
    this.page = 1
    addQueryParam('reqP', 1)
    if (this.statusFilter.value) {
      addQueryParam('reqS', status.value)
    } else {
      removeQueryParam('reqS')
    }
    this.loadRequests()
  }

  changeScheduleFilter = status => {
    this.scheduleFilter = status
    this.page = 1
    addQueryParam('reqP', 1)
    if (this.scheduleFilter.value) {
      addQueryParam('reqC', status.value)
    } else {
      removeQueryParam('reqC')
    }
    this.loadRequests()
  }

  formatScheduleTime = (timeFrom, timeTo, noHrCount) => {
    const fromT = moment(timeFrom)
    const toT = moment(timeTo)
    const duration = moment.duration(toT.diff(fromT))
    return noHrCount
      ? `${fromT.format('HH:mm')} - ${toT.format('HH:mm')}`
      : `${fromT.format('HH:mm')} - ${toT.format('HH:mm')} (${duration.hours()} ${
          duration.hours() > 1 ? 'hrs' : 'hr'
        })`
  }

  dayOfTheWeekTitle = title => {
    switch (title) {
      case 'mon':
        return 'Monday'
      case 'tue':
        return 'Tuesday'
      case 'wed':
        return 'Wednesday'
      case 'thu':
        return 'Thursday'
      case 'fri':
        return 'Friday'
      case 'sat':
        return 'Saturday'
      case 'sun':
        return 'Sunday'
      default:
        return 'None'
    }
  }

  loadRequests = () => {
    this.isLoadingRequests = true
    if (this.adType === 'Help') {
      this.loadRequestsHelp()
    } else {
      this.loadRequestsWork()
    }
  }

  loadRequestsHelp = () => {
    adsApi
      .getHelpAdRequests(
        this.adId,
        this.limit,
        this.offset,
        this.statusFilter.value ? this.statusFilter.value : '',
        this.ordering,
        this.scheduleDate.timeFrom,
        this.scheduleDate.timeTo
      )
      .then(response => {
        this.total = response.count
        const readyData = []
        response.results.forEach(item => {
          const entity = {}
          entity.data = []
          entity.id = item.id
          entity.data.push(moment(item.date_created).format('DD.MM.YYYY  HH:mm').toString())
          entity.data.push(item.request_user.full_name)
          entity.data.push(item.request_user.email)
          entity.data.push(
            `${moment(item.time_from).format('DD.MM.YYYY  HH:mm').toString()} - ${
              item.ended_at
                ? moment(item.ended_at).format('DD.MM.YYYY HH:mm')
                : moment(item.time_to).format('DD.MM.YYYY HH:mm')
            }`
          )
          entity.data.push(
            `${CURRENCY_DICTIONARY.getCurrency(item.currency)} ${+item.payout.toFixed(1)}`
          )
          entity.data.push(item.status)
          readyData.push(entity)
        })
        this.requestsData = readyData
      })
      .catch(error => {
        toast.error('Error when trying to get requests')
      })
      .finally(() => {
        this.isLoadingRequests = false
      })
  }

  loadDataWork = () => {
    this.isFetching = true
    adsApi
      .getWorks(
        this.limit,
        this.offset,
        this.ordering,
        this.subcategory ? this.subcategory.value : ''
      )
      .then(response => {
        this.total = response.count
        const readyData = []
        response.results.forEach(item => {
          const entity = {}
          entity.data = []
          entity.isOpen = false
          entity.id = item.id
          entity.data.push(
            `${moment(item.date_created).format('DD.MM.YYYY').toString()}\n${moment(
              item.date_created
            ).format('HH:mm')}`
          )
          entity.data.push(item.creator.full_name)
          entity.data.push(item.category_title)
          entity.data.push(item.interest.label)
          entity.data.push(item.title)
          entity.data.push(`${CURRENCY_DICTIONARY.getCurrency(item.currency)} ${item.hourly_rate}`)
          entity.data.push(item.hours_amount)
          entity.data.push(item.total_value)
          entity.data.push(item.status)
          entity.additionalInfo = []
          entity.additionalInfo.push(item.creator.email)
          entity.additionalInfo.push(item.description)
          entity.additionalInfo.push(item.additional_requirements)
          entity.additionalInfo.push(
            `${item.street ? `${item.street}, ` : ''}
            ${item.city ? `${item.city}, ` : ''}
            ${item.country ? `${item.country}` : ' '}`
          )
          entity.availability = []
          item.work_dates.forEach(date => {
            const dayOfTheWeek = moment(date.date, 'YYYY-MM-DD').format('DD MMM')
            const timeFrom = moment(date.time_from).format('HH:mm')
            const timeTo = moment(date.time_to).format('HH:mm')
            entity.availability.push(`${dayOfTheWeek} ${timeFrom} - ${timeTo}`)
          })
          readyData.push(entity)
        })
        if (!readyData.length) {
          if (this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.workAds = readyData
          }
        } else {
          this.workAds = readyData
          this.fieldListWork = response.results
        }
      })
      .catch(error => {
        toast.error('Error when trying to get work ads')
      })
      .finally(() => {
        this.isFetching = false
      })
  }
  loadData = () => {
    this.isFetching = true
    adsApi
      .getHelps(
        this.limit,
        this.offset,
        this.ordering,
        this.subcategory ? this.subcategory.value : ''
      )
      .then(response => {
        this.total = response.count
        const readyData = []
        response.results.forEach(item => {
          const entity = {}
          entity.data = []
          entity.isOpen = false
          entity.id = item.id
          entity.data.push(
            `${moment(item.date_created).format('DD.MM.YYYY').toString()}\n${moment(
              item.date_created
            ).format('HH:mm')}`
          )
          entity.data.push(item.creator.full_name)
          entity.data.push(item.category_title)
          entity.data.push(item.interest.label)
          entity.data.push(item.title)
          entity.data.push(`${CURRENCY_DICTIONARY.getCurrency(item.currency)} ${item.hourly_rate}`)
          entity.data.push(item.status)
          entity.additionalInfo = []
          entity.additionalInfo.push(item.creator.email)
          entity.additionalInfo.push(item.description)
          entity.additionalInfo.push(
            `${item.street ? `${item.street}, ` : ''}
            ${item.city ? `${item.city}, ` : ''}
            ${item.country ? `${item.country}` : ''}`
          )
          const timeFrom = moment(item.availability.time_from, 'HH:mm:s').format('HH:mm')
          const timeTo = moment(item.availability.time_to, 'HH:mm:s').format('HH:mm')
          entity.availability = []
          entity.availability = Object.entries(item.availability)
            .filter(([key, value]) => {
              return key !== 'time_from' && key !== 'time_to' && value
            })
            .map(item => {
              return `${item[0]} ${timeFrom} - ${timeTo}`
            })
          readyData.push(entity)
        })
        if (!readyData.length) {
          if (this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.helpAds = readyData
          }
        } else {
          this.helpAds = readyData
          this.fieldList = response.results
        }
      })
      .catch(error => {
        toast.error('Error when trying to get help ads')
      })
      .finally(() => {
        this.isFetching = false
      })
  }

  editHelp = () => {
    if (/type=([a-zA-Z]+)/g.exec(this.props.location.search)[1] === 'Help') {
      const field = this.fieldList.find(field => field.id === this.adData.id)
      this.props.modalsStore.openEditAdsModal(
        field,
        this.categories,
        this.regularCategories,
        this.loadData
      )
    }
    if (/type=([a-zA-Z]+)/g.exec(this.props.location.search)[1] === 'Work') {
      const field = this.fieldListWork.find(field => field.id === this.adData.id)
      this.props.modalsStore.openWorksAdsModal(
        field,
        this.categories,
        this.regularCategories,
        this.loadDataWork
      )
    }
  }

  loadRequestsWork = () => {
    adsApi
      .getWorkAdRequests(
        this.adId,
        this.limit,
        this.offset,
        this.statusFilter.value ? this.statusFilter.value : '',
        this.scheduleFilter.value ? this.scheduleFilter.value : '',
        this.ordering
      )
      .then(response => {
        this.total = response.count
        const readyData = []
        response.results.forEach(item => {
          const entity = {}
          entity.data = []
          entity.id = item.id
          entity.data.push(moment(item.date_created).format('DD.MM.YYYY  HH:mm').toString())
          entity.data.push(item.request_user.full_name)
          entity.data.push(item.request_user.email)
          const workDate = this.adData.work_dates.filter(
            itemDate => itemDate.id === item.work_date
          )[0]
          entity.data.push(
            `${moment(workDate.time_from).format('DD.MM.YYYY HH:mm')} - ${
              workDate.ended_at
                ? moment(workDate.ended_at).format('DD.MM.YYYY HH:mm')
                : moment(workDate.time_to).format('DD.MM.YYYY HH:mm')
            }`
          )
          entity.data.push(item.status)
          readyData.push(entity)
        })
        if (!readyData.length) {
          if (this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.requestsData = readyData
          }
        } else {
          this.requestsData = readyData
        }
      })
      .catch(error => {
        toast.error('Error when trying to get requests')
      })
      .finally(() => {
        this.isLoadingRequests = false
      })
  }

  changePage = page => {
    this.page = page
    addQueryParam('reqP', page)
    this.loadRequests()
  }

  changeOrder = param => {
    this.ordering = param
    addQueryParam('reqO', this.ordering)
    this.loadRequests()
  }

  changeLimit = limit => {
    this.limit = limit.value
    this.page = 1
    addQueryParam('reqL', limit.value)
    addQueryParam('reqP', 1)
    this.loadRequests()
  }

  @computed get offset() {
    return (this.page - 1) * this.limit
  }

  @computed get totalPages() {
    return Math.ceil(this.total / this.limit)
  }

  @computed get schedulesOptions() {
    const options = [
      {
        label: 'All'
      }
    ]
    if (this.adData) {
      if (this.adType === 'Work') {
        options.push(
          ...this.adData.work_dates.map(item => {
            return {
              label: moment(item.date, 'YYYY-MM-DD').format('DD MMM, YYYY'),
              content: (
                <div>
                  <div
                    style={{
                      color: '#808080',
                      fontSize: '12px',
                      fontWeight: '400',
                      marginBottom: '5px'
                    }}
                  >
                    {moment(item.date, 'YYYY-MM-DD').format('DD MMM, YYYY')}
                  </div>
                  <div>{this.formatScheduleTime(item.time_from, item.time_to, true)}</div>
                </div>
              ),
              value: item.id
            }
          })
        )
        return options
      } else {
        return options
      }
    } else {
      return options
    }
  }

  @computed get getAttachments() {
    return this.adType === 'Help' ? this.adData.help_attachments : this.adData.attachments
  }

  @computed get scheduleDate() {
    if (this.scheduleFilter !== 'All') {
      return {
        timeFrom: moment(this.scheduleFilter[0]).format('YYYY-MM-DD HH:m'),
        timeTo: moment(this.scheduleFilter[1]).format('YYYY-MM-DD HH:m')
      }
    } else {
      return this.scheduleFilter
    }
  }

  @computed get scheduleDateFormatted() {
    return this.scheduleFilter !== 'All'
      ? `${moment(this.scheduleDate.timeFrom).format('DD.MM.YY')} - ${moment(
          this.scheduleDate.timeTo
        ).format('DD.MM.YY')}`
      : this.scheduleFilter
  }

  @computed get availability() {
    /*
      10 is returned to omit availability existence check
      (for work ad we dont't need this check anyway)
     */
    return this.adData.availability
      ? Object.entries(this.adData.availability).filter(([key, value]) => {
          return key !== 'time_from' && key !== 'time_to' && value
        })
      : 10
  }

  @computed get availabilityStyles() {
    if (this.adType === 'Help') {
      return this.availability.length > 4
        ? {
            gridTemplateColumns: '1fr 1fr'
          }
        : {
            gridTemplateColumns: '1fr'
          }
    }
    return {}
  }

  @computed get formattedLocation() {
    const formatLocation = (param1, param2, param3) => {
      if (param2 || param3) {
        return param1 ? `${param1}, ` : ''
      } else {
        return param1
      }
    }
    return `${formatLocation(
      this.adData.street,
      this.adData.city,
      this.adData.country
    )}${formatLocation(this.adData.city, this.adData.country, undefined)}${formatLocation(
      this.adData.country,
      undefined,
      undefined
    )}`
  }

  changeScheduleDate = val => {
    this.calendarOpen = false
    this.setState({ dateSchedule: val })
    this.scheduleFilter = val
    this.loadRequests()
  }

  clearCalendar = () => {
    this.scheduleFilter = 'All'
    this.setState({ dateSchedule: [new Date(), new Date()] })
    this.loadRequests()
  }

  render() {
    return (
      <PageWrap heading={`${this.adType} ad details`} hasBackBtn>
        <div className={styles['ad-details']}>
          {!this.dataIsLoading && (
            <div className={styles['ad-details__top']}>
              <Card header="summary">
                <div className={styles['card']}>
                  <h3 className={styles['card__title']}>{this.adData.title}</h3>
                  <div className={styles['card__section']}>
                    <div className={styles['card__section_title']}>Description</div>
                    <div className={styles['card__section_body']}>
                      <Text text={this.adData.description} />
                    </div>
                  </div>
                  {this.adType === 'Work' && (
                    <div className={styles['card__section']}>
                      <div className={styles['card__section_title']}>Additional requirements</div>
                      <div className={styles['card__section_body']}>
                        <Text text={this.adData.additional_requirements} />
                      </div>
                    </div>
                  )}
                  <div className={styles['card__section-bottom']}>
                    <div>
                      <div className={styles['card__section_title']}>Interest/Task</div>
                      <div className={styles['card__section_body']}>
                        <Text
                          text={`${this.adData.category_title} / ${this.adData.interest.label}`}
                        />
                      </div>
                    </div>
                    <div>
                      <div className={styles['card__section_title']}>Hourly rate</div>
                      <div className={styles['card__section_body']}>
                        <div className={styles['card__amount']}>
                          {`${CURRENCY_DICTIONARY.getCurrency(this.adData.currency)} ${
                            this.adData.hourly_rate
                          }`}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles['card__section_title']}>Location</div>
                      <div className={styles['card__section_body']}>
                        <Text text={this.formattedLocation} />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>

              <div
                className={classNames(
                  styles['card__wrap'],
                  this.availability.length < 5 && styles['card__wrap--small']
                )}
              >
                <Card header={this.adType === 'Help' ? 'availability' : 'schedules'} noPad>
                  <div
                    className={classNames(
                      this.adType === 'Help'
                        ? styles['card-availability']
                        : styles['card-schedules'],
                      this.availability.length < 5 && styles['card-availability-noBorder']
                    )}
                    style={this.availabilityStyles}
                  >
                    {this.adType === 'Help'
                      ? this.availability.map((item, index) => {
                          return (
                            <div key={index} className={styles['card-availability__wrap']}>
                              <div className={styles['card__section_title']}>
                                {this.dayOfTheWeekTitle(item[0])}
                              </div>
                              <div className={styles['card__section_body']}>
                                {`${moment(this.adData.availability.time_from, 'HH:mm').format(
                                  'HH:mm'
                                )} - ${moment(this.adData.availability.time_to, 'HH:mm').format(
                                  'HH:mm'
                                )}`}
                              </div>
                            </div>
                          )
                        })
                      : this.adData.work_dates.map((item, index) => {
                          return (
                            <div key={index} className={styles['card-schedules__wrap']}>
                              <div className={styles['card-schedules__left']}>
                                <div className={styles['card__section_title']}>
                                  {moment(item.date, 'YYYY-MM-DD').format('DD MMM, YYYY')}
                                </div>
                                <div className={styles['card__section_body']}>
                                  {this.formatScheduleTime(item.time_from, item.time_to)}
                                </div>
                              </div>
                              <div className={styles['card-schedules__right']}>
                                <div className={styles['card__amount']}>
                                  {`${CURRENCY_DICTIONARY.getCurrency(this.adData.currency)} ${
                                    item.amount
                                  }`}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                  </div>
                </Card>
              </div>
            </div>
          )}
        </div>

        {this.adData && (
          <>
            {this.getAttachments.length > 0 && (
              <div className={styles['ad-details__attachments']}>
                <h4>Photos</h4>
                <div className={styles['ad-details__attachments_row']}>
                  {this.getAttachments.map(item => {
                    return (
                      <img key={item.id} src={`${axios.defaults.baseURL}/${item.file}`} alt="" />
                    )
                  })}
                </div>
              </div>
            )}
          </>
        )}

        <div className={styles['ad-details__bottom']}>
          <h4>Requests</h4>
          <div className={styles['ad-details__bottom_filters']}>
            <DropdownSelect
              label="by status"
              value={this.statusFilter.label || 'All'}
              options={[
                {
                  label: 'All'
                },
                {
                  label: 'Completed',
                  value: 'Completed'
                },
                {
                  label: 'Expired',
                  value: 'Expired'
                },
                {
                  label: 'Rejected',
                  value: 'Rejected'
                },
                {
                  label: 'Canceled',
                  value: 'Canceled'
                },
                {
                  label: this.adType === 'Help' ? 'Applied' : 'Requested',
                  value: this.adType === 'Help' ? 'Applied' : 'Requested'
                },
                {
                  label: 'In progress',
                  value: 'In progress'
                },
                {
                  label: 'Coming up',
                  value: 'Coming up'
                }
              ]}
              onSelect={this.changeStatusFilter}
            />
            {this.adType === 'Help' ? (
              <div className={styles['calendar__wrap']}>
                <SimpleDropdown
                  value={this.scheduleDateFormatted}
                  label="by schedules"
                  onOpen={val => {
                    this.calendarOpen = val
                  }}
                  width={240}
                  isOpen={this.calendarOpen}
                  onClear={this.clearCalendar}
                  hasClearBtn
                >
                  <DateRangePicker
                    value={this.state.dateSchedule}
                    onChange={this.changeScheduleDate}
                    className={styles['calendar']}
                    locale="en"
                    isOpen={this.calendarOpen}
                  />
                </SimpleDropdown>
              </div>
            ) : (
              <DropdownSelect
                label="by schedules"
                value={this.scheduleFilter.label || 'All'}
                options={this.schedulesOptions}
                onSelect={this.changeScheduleFilter}
              />
            )}
            <div className={styles['ed-btn']} onClick={this.editHelp}>
              <Icon svg={editIcon} colorStroke="light-gray" colorFill="light-gray" />
            </div>
          </div>
          {this.adType === 'Help' ? (
            <Table
              headings={[
                {
                  title: 'date and time sent',
                  filter: 'date_created'
                },
                {
                  title: 'user name',
                  filter: 'request_user__full_name'
                },
                {
                  title: 'email'
                },
                {
                  title: 'selected date/time'
                },
                {
                  title: 'payout',
                  filter: 'payout'
                },
                {
                  title: 'status',
                  filter: 'status'
                }
              ]}
              isFetching={this.isLoadingRequests}
              data={this.requestsData}
              onOrderChange={this.changeOrder}
              activeOrder={this.ordering}
            />
          ) : (
            <Table
              headings={[
                {
                  title: 'date and time sent',
                  filter: 'date_created'
                },
                {
                  title: 'user name',
                  filter: 'request_user__full_name'
                },
                {
                  title: 'email'
                },
                {
                  title: 'selected work date'
                },
                {
                  title: 'status',
                  filter: 'status'
                }
              ]}
              isFetching={this.isLoadingRequests}
              data={this.requestsData}
              onOrderChange={this.changeOrder}
              activeOrder={this.ordering}
            />
          )}
          <Pagination
            totalPages={this.totalPages}
            currentPage={this.page}
            limit={this.limit}
            onLimitChange={this.changeLimit}
            onPagination={this.changePage}
          />
        </div>
      </PageWrap>
    )
  }
}

export default AdDetails
