import React from 'react'
import PrivateRoute from './PrivateRoute.jsx'
import LoginRoute from './LoginRoute.jsx'

import Login from '../components/pages/Login'
import Ads from '../components/pages/Ads'
import Users from '../components/pages/Users'
import AdDetails from '../components/pages/AdDetails'
import UserDetails from '../components/pages/UserDetails'

export default [
  <LoginRoute key="login" path="/login" component={Login} />,
  <PrivateRoute key="ads" path="/" component={Ads} exact />,
  <PrivateRoute key="ad-details" path="/ad-details" component={AdDetails} exact />,
  <PrivateRoute key="users" path="/users" component={Users} exact />,
  <PrivateRoute key="user-details" path="/user-details" component={UserDetails} exact />
]
