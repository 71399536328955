import { withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, computed } from 'mobx'
import types from 'prop-types'
import classNames from 'classnames'
import Icon from '../../atoms/Icon'
import DropdownSelect from '../../molecules/DropdownSelect'
import styles from './styles.module.scss'
import dropIcon from '../../../assets/icons/drop.svg'


@withRouter
@observer
class Pagination extends Component {

  static propTypes = {
    currentPage: types.oneOfType([types.number, types.string]),
    totalPages: types.oneOfType([types.number, types.string]),
    onPagination: types.func,
    onLimitChange: types.func,
    limit: types.oneOfType([types.number, types.string])
  }

  static defaultProps = {
    onPagination: () => {}
  }

  @observable currentPage = 1
  @observable pages = []

  constructor(props) {
    super(props)
    this.currentPage = props.currentPage
    this.pages = [...Array(props.totalPages).keys()].map(x => ++x)
  }

  goToPage = (pageNum) => {
    if (pageNum > 0 && pageNum <= this.props.totalPages) {
      this.currentPage = pageNum
      this.props.onPagination(this.currentPage)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.pages = [...Array(nextProps.totalPages).keys()].map(x => ++x)
    this.currentPage = nextProps.currentPage
  }

  @computed get slicedRange() {
    if (this.props.totalPages > 5) {
      const slicedPages = []
      let prevPage = this.currentPage - 1
      let nextPage = this.currentPage + 1
      if (prevPage > 2) {
        slicedPages.push(1)
        slicedPages.push('...')
        slicedPages.push(prevPage)
      } else {
        if (prevPage === 2) {
          slicedPages.push(1)
        }
        if (prevPage > 0) {
          slicedPages.push(prevPage)
        }
      }
      slicedPages.push(this.currentPage)
      if (nextPage <= this.props.totalPages) {
        slicedPages.push(nextPage)
      }
      if (nextPage < this.props.totalPages - 2) {
        slicedPages.push('...')
        slicedPages.push(this.props.totalPages)
      }
      return slicedPages
    } else {
      return this.pages
    }
  }

  render() {
    return (
      <div className={styles['pagination__wrapper']}>
        <DropdownSelect
          label='Show'
          value={this.props.limit.toString()}
          options={[{
            label: '10',
            value: 10
          }, {
            label: '25',
            value: 25
          }, {
            label: '50',
            value: 50
          }]}
          onSelect={this.props.onLimitChange}
          forPagination
        />
        {this.props.totalPages > 1 && (
          <div className={styles['pagination']}>
            {this.currentPage !== 1 &&
            <div
              className={classNames(styles['pagination__button'],
                styles['pagination__button--prev'])}
              onClick={() => this.goToPage(this.currentPage - 1)}
            >
              <Icon svg={dropIcon}/>
            </div>
            }
            {this.slicedRange.map((item, index) => (
              <span
                className={item === this.currentPage ? classNames(styles['pagination-page'],
                  styles['pagination-page--active'])
                  : styles['pagination-page']}
                key={index}
                onClick={() => this.goToPage(item)}
              >
              {item}
            </span>
            ))}
            {this.currentPage !== this.props.totalPages &&
            <div
              className={classNames(styles['pagination__button'],
                styles['pagination__button--next'])}
              onClick={() => this.goToPage(this.currentPage + 1)}
            >
              <Icon svg={dropIcon}/>
            </div>
            }
          </div>
        )}
      </div>
    )
  }

}

export default Pagination;
