exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../../assets/images/bg_collabry.png"));

// Module
exports.push([module.id, ".styles_sidebar__1Ftrf {\n  width: 300px;\n  min-height: 100vh;\n  position: fixed;\n  background-color: black; }\n  .styles_sidebar__placeholder__2oyWT {\n    flex: 0 0 300px;\n    min-height: 100vh; }\n  .styles_sidebar__copyright__Cl-bb {\n    margin: 40px auto;\n    font-size: 14px;\n    text-align: center;\n    color: #686868;\n    -webkit-user-select: none;\n            user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    position: absolute;\n    bottom: 0;\n    width: 100%; }\n  .styles_sidebar__bg__3gq12 {\n    background: no-repeat url(" + ___CSS_LOADER_URL___0___ + ");\n    height: calc(100% - 120px);\n    width: 100%;\n    top: 120px;\n    position: absolute;\n    background-size: contain; }\n  .styles_sidebar__menu-logo__cEmGE {\n    width: 132px;\n    padding: 32px 32px 10px 40px; }\n    .styles_sidebar__menu-logo-wrap__1jezb {\n      width: 100%; }\n  .styles_sidebar__menu-bottom__18esf {\n    position: absolute;\n    width: 100%;\n    bottom: 100px; }\n", ""]);

// Exports
exports.locals = {
	"sidebar": "styles_sidebar__1Ftrf",
	"sidebar__placeholder": "styles_sidebar__placeholder__2oyWT",
	"sidebar__copyright": "styles_sidebar__copyright__Cl-bb",
	"sidebar__bg": "styles_sidebar__bg__3gq12",
	"sidebar__menu-logo": "styles_sidebar__menu-logo__cEmGE",
	"sidebar__menu-logo-wrap": "styles_sidebar__menu-logo-wrap__1jezb",
	"sidebar__menu-bottom": "styles_sidebar__menu-bottom__18esf"
};