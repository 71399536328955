exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_icon__2aS-e {\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n  .styles_icon__2aS-e svg {\n    display: flex; }\n  .styles_icon__image--red-fill__23yAs svg {\n    fill: #FF3E5F; }\n  .styles_icon__image--red-stroke__2Tri- svg g {\n    stroke: #FF3E5F; }\n  .styles_icon__image--black-fill__VzkgW svg {\n    fill: #000000; }\n    .styles_icon__image--black-fill__VzkgW svg g {\n      fill: #000000; }\n  .styles_icon__image--light-gray-fill__2-b-p svg {\n    fill: #B3B3B3; }\n    .styles_icon__image--light-gray-fill__2-b-p svg g {\n      fill: #B3B3B3; }\n  .styles_icon__image--light-gray-stroke__32J2R svg g {\n    stroke: #B3B3B3; }\n  .styles_icon__image--red-gFill__3QHaR svg g {\n    fill: #FF3E5F; }\n  .styles_icon__small__2yOA- svg {\n    width: 16px;\n    height: 16px; }\n  .styles_icon__image--white-fill__RDN9r svg {\n    fill: #FFFFFF; }\n    .styles_icon__image--white-fill__RDN9r svg g {\n      fill: #FFFFFF; }\n  .styles_icon__image--white-stroke__2k7EO svg g {\n    stroke: #FFFFFF; }\n", ""]);

// Exports
exports.locals = {
	"icon": "styles_icon__2aS-e",
	"icon__image--red-fill": "styles_icon__image--red-fill__23yAs",
	"icon__image--red-stroke": "styles_icon__image--red-stroke__2Tri-",
	"icon__image--black-fill": "styles_icon__image--black-fill__VzkgW",
	"icon__image--light-gray-fill": "styles_icon__image--light-gray-fill__2-b-p",
	"icon__image--light-gray-stroke": "styles_icon__image--light-gray-stroke__32J2R",
	"icon__image--red-gFill": "styles_icon__image--red-gFill__3QHaR",
	"icon__small": "styles_icon__small__2yOA-",
	"icon__image--white-fill": "styles_icon__image--white-fill__RDN9r",
	"icon__image--white-stroke": "styles_icon__image--white-stroke__2k7EO"
};