export const conditionalGetParam = (param, paramName) => {
  return param ? `${paramName}=${param}&` : ''
}

export const formIdArrayString = objArray => {
  return objArray ? objArray.map(item => {
    return item.id
  }).join(',') : ''
}

export const pushOrRemove = (array, item) => {
  let itemIn = array.indexOf(item)
  return itemIn === -1 ? array.concat(item) : array.filter(i => i !== item)
}

export const nullCheck = (value, defaultValue = ' ') => {
  return value === null ? defaultValue : value
}
