import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { computed, observable } from 'mobx'
import { addQueryParam, getQueryParam } from '../../../helpers/history'
import { usersApi } from '../../../api'
import { nullCheck } from '../../../helpers/utils'
import moment from 'moment'
import { toast } from 'react-toastify'
import InviteModal from '../../organisms/InviteModal'
import Pagination from '../../organisms/Pagination'
import SearchInput from '../../molecules/SearchInput'
import Table from '../../organisms/Table'
import Button from '../../molecules/Button'
import styles from './styles.module.scss'


@inject('modalsStore')
@observer
class UsersInvited extends Component {

  @observable usersData = []

  @observable ordering = ''
  @observable search = ''
  @observable limit = 10
  @observable total = 0
  @observable page = 0
  @observable isFetching = false

  constructor(props) {
    super(props)
    this.page = Number.parseInt(getQueryParam('iP', 1))
    this.limit = getQueryParam('iL', 10)
    this.ordering = getQueryParam('iO', '')
    this.search = getQueryParam('iS', '')
    this.loadData()
  }

  loadData = () => {
    this.isFetching = true
    usersApi.getUsers(this.limit, this.offset, true, this.search, this.ordering)
      .then(response => {
        this.total = response.count
        const readyData = []
        response.results.forEach((item) => {
          const entity = {}
          entity.data = []
          entity.id = item.id
          entity.data.push(nullCheck(item.full_name))
          entity.data.push(nullCheck(item.email))
          entity.data.push(nullCheck(moment(item.invitation_date).format('DD.MM.YYYY').toString()))
          entity.data.push('')
          readyData.push(entity)
        })
        if (!readyData.length) {
          if (this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.usersData = readyData
          }
        } else {
          this.usersData = readyData
        }
      })
      .catch(error => {
        toast.error('Error when trying to get invited users')
      })
      .finally(() => {
        this.isFetching = false
      })
  }

  changeOrder = (param) => {
    this.ordering = param
    addQueryParam('iO', this.ordering)
    this.loadData()
  }

  changeSearch = search => {
    this.search = search
    this.page = 1
    addQueryParam('iS', search)
    addQueryParam('iP', 1)
    this.loadData()
  }

  deleteUser = id => {
    this.props.modalsStore.openConfirmModal(
      'Deletion',
      'Are you sure you want to delete this user?',
      () => {
        usersApi.deleteUser(id)
          .then(response => {
            this.loadData()
            toast.success('User was successfully deleted')
          })
          .catch(error => {
            toast.error('Error when trying to remove user')
          })
      }
    )
  }

  changePage = page => {
    this.page = page
    addQueryParam('iP', page)
    this.loadData()
  }

  changeLimit = limit => {
    this.limit = limit.value
    this.page = 1
    addQueryParam('iL', limit.value)
    addQueryParam('iP', 1)
    this.loadData()
  }

  resendInvite = id => {
    usersApi.resendInvitation(id)
      .then(response => {
        toast.success('Invitation was resend')
      })
      .catch(error => {
        toast.error('Error while trying to resend invitation')
      })
  }

  @computed get offset() {
    return (this.page - 1) * this.limit
  }

  @computed get totalPages() {
    return Math.ceil(this.total / this.limit)
  }

  render() {
    return(
      <div className={styles['invited']}>
        <div className={styles['invited__header']}>
          <SearchInput
            placeholder='Search Person'
            value={this.search}
            onChange={this.changeSearch}
          />
          <div className={styles['invited__inviteBtn']}>
            <Button
              text='Invite Client'
              onClick={() => this.props.modalsStore.openInviteModal()}
              smaller
            />
          </div>
        </div>
        <Table
          headings={[{
            title: 'full name',
            filter: 'full_name'
          }, {
            title: 'email'
          }, {
            title: 'invitation date',
            filter: 'invitation_date'
          }, {
            title: 'actions'
          }]}
          data={this.usersData}
          onOrderChange={this.changeOrder}
          activeOrder={this.ordering}
          isFetching={this.isFetching}
          deleteAction={this.deleteUser}
          actionColumn='actions'
          addedActionBtn='Resend Invitation'
          addedActionBtnFun={this.resendInvite}
          hasDeleteAction
        />
        <Pagination
          totalPages={this.totalPages}
          currentPage={this.page}
          limit={this.limit}
          onLimitChange={this.changeLimit}
          onPagination={this.changePage}
        />
        <InviteModal
          closeCb={this.loadData}
        />
      </div>
    )
  }

}

export default UsersInvited
