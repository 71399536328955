import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import PreloaderPage from '../../atoms/PreloaderPage'
import Rating from '../../molecules/Rating'
import Button from '../../molecules/Button'
import ConditionalWrapper from '../../prototypes/ConditionalWrapper'
import { Link } from 'react-router-dom'
import dropIcon from '../../../assets/icons/drop.svg'
import deleteIcon from '../../../assets/icons/delete.svg'
import editIcon from '../../../assets/icons/edit.svg'
import types from 'prop-types'
import classNames from 'classnames'
import { utcToZonedTime } from 'date-fns-tz'
import moment from 'moment'
import styles from './styles.module.scss'

@observer
class Table extends Component {
  static propTypes = {
    headings: types.array,
    data: types.object,
    fieldList: types.object,
    hasAdditionalInfo: types.bool,
    showInfoClick: types.func,
    additionalHeadings: types.array,
    subCategories: types.oneOfType([types.array, types.object]),
    regCategories: types.oneOfType([types.array, types.object]),
    onOrderChange: types.func,
    activeOrder: types.string,
    isFetching: types.bool,
    onDelete: types.func,
    onEdit: types.func,
    rowRedirect: types.string,
    ratingColumn: types.string,
    actionColumn: types.string,
    hasDeleteAction: types.bool,
    deleteAction: types.func,
    addedActionBtn: types.string,
    addedActionBtnFun: types.func
  }

  static defaultProps = {
    additionalHeadings: []
  }

  @observable activeFilter = ''

  constructor(props) {
    super(props)
    this.activeFilter = this.props.activeOrder || ''
  }

  orderChange = (fieldName, isReverse) => {
    if (!isReverse) {
      if (fieldName) {
        if (this.activeFilter === fieldName) {
          this.activeFilter = `-${fieldName}`
        } else if (this.activeFilter === `-${fieldName}`) {
          this.activeFilter = ''
        } else {
          this.activeFilter = fieldName
        }
        this.props.onOrderChange(this.activeFilter)
      }
    } else {
      if (fieldName) {
        if (this.activeFilter === fieldName) {
          this.activeFilter = ''
        } else if (this.activeFilter === `-${fieldName}`) {
          this.activeFilter = fieldName
        } else {
          this.activeFilter = `-${fieldName}`
        }
        this.props.onOrderChange(this.activeFilter)
      }
    }
  }

  renderColumn = (item, index, id) => {
    if (this.props.actionColumn === this.props.headings[index].title) {
      if (this.props.hasDeleteAction) {
        if (this.props.addedActionBtn) {
          return (
            <div className={styles['action__buttons_wrapper']}>
              <div className={styles['action__button_added']}>
                <Button
                  text={this.props.addedActionBtn}
                  onClick={() => this.props.addedActionBtnFun(id)}
                  isWhite
                />
              </div>
              <Button iconSvg={deleteIcon} onClick={() => this.props.deleteAction(id)} isWhite />
            </div>
          )
        } else {
          return <Button iconSvg={deleteIcon} onClick={() => this.props.deleteAction(id)} isWhite />
        }
      } else {
        return item
      }
    } else if (this.props.ratingColumn === this.props.headings[index].title) {
      return <Rating rating={item} />
    } else {
      return <Text text={item} />
    }
  }

  render() {
    return (
      <div
        className={styles['table__wrapper']}
        style={{ minHeight: this.props.isFetching ? '400px' : 'auto' }}
      >
        {this.props.isFetching && <PreloaderPage absolute />}
        <table className={styles['table']}>
          <thead className={styles['table__header']}>
            <tr>
              {this.props.headings.map((item, index) => (
                <th key={index}>
                  <div
                    className={classNames(
                      styles['table__header--col'],
                      item.filter && styles['table__header--col-filter']
                    )}
                    onClick={() => this.orderChange(item.filter, item.reverse)}
                  >
                    <div
                      className={classNames(
                        styles['table__header--col-text'],
                        (this.activeFilter === item.filter ||
                          this.activeFilter === `-${item.filter}`) &&
                          styles['table__header--col-text-active']
                      )}
                    >
                      {item.title}
                    </div>
                    {item.filter && (
                      <div
                        className={classNames(
                          styles['table__header--col-icon'],
                          (!item.reverse
                            ? this.activeFilter === item.filter
                            : this.activeFilter === `-${item.filter}`) &&
                            styles['table__header--col-icon-reverse'],
                          this.activeFilter === `-${item.filter}` &&
                            styles['table__header--col-icon-active']
                        )}
                      >
                        <Icon
                          svg={dropIcon}
                          colorFill={
                            this.activeFilter === item.filter ||
                            this.activeFilter === `-${item.filter}`
                              ? 'black'
                              : 'light-gray'
                          }
                        />
                      </div>
                    )}
                  </div>
                </th>
              ))}
              {this.props.hasAdditionalInfo && (
                <th className={styles['table__header--col-dropdown']} />
              )}
            </tr>
          </thead>
          <tbody className={styles['table__body']}>
            {this.props.data.length ? (
              <>
                {this.props.data.map((row, indexRow) => {
                  return (
                    <React.Fragment key={row.id}>
                      <tr
                        className={classNames(
                          styles['table__body--row'],
                          indexRow !== this.props.data.length - 1 &&
                            styles['table__body--row-line'],
                          row.isOpen && styles['table__body--row-open']
                        )}
                      >
                        {row.data.map((item, index) => {
                          return (
                            <td
                              key={index}
                              className={classNames(
                                this.props.actionColumn === this.props.headings[index].title &&
                                  styles['table__body--td-actions']
                              )}
                            >
                              <ConditionalWrapper
                                condition={this.props.rowRedirect}
                                wrapper={children => (
                                  <Link
                                    to={`${this.props.rowRedirect}${
                                      this.props.rowRedirect.includes('?') ? '&' : '?'
                                    }id=${row.id}`}
                                  >
                                    {children}
                                  </Link>
                                )}
                              >
                                <div
                                  className={classNames(
                                    styles['table__body--col'],
                                    this.props.actionColumn === this.props.headings[index].title &&
                                      styles['table__body--col-actions']
                                  )}
                                >
                                  <div className={styles['table__body--col-text']}>
                                    {this.renderColumn(item, index, row.id)}
                                  </div>
                                </div>
                              </ConditionalWrapper>
                            </td>
                          )
                        })}
                        {this.props.hasAdditionalInfo && (
                          <td
                            className={styles['table__body--col-dropdown']}
                            onClick={() => this.props.showInfoClick(indexRow)}
                          >
                            <div
                              className={classNames(
                                styles['table__body--col-dropdown-wrap'],
                                row.isOpen && styles['table__body--col-dropdown-wrap-open']
                              )}
                            >
                              <Icon
                                svg={dropIcon}
                                colorStroke="light-gray"
                                colorFill="light-gray"
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                      {this.props.hasAdditionalInfo && this.props.data && (
                        <tr
                          className={classNames(
                            styles['table__body--row-additional'],
                            row.isOpen && styles['table__body--row-additional-show']
                          )}
                        >
                          <td colSpan={this.props.headings.length + 1}>
                            <div className={styles['info']}>
                              <div className={styles['info__col']}>
                                {this.props.additionalHeadings.map((item, index) => {
                                  return (
                                    <div key={index} className={styles['info__col-heading']}>
                                      {item}
                                    </div>
                                  )
                                })}
                              </div>
                              <div className={styles['info__col']}>
                                {this.props.data[indexRow].additionalInfo.map((item, index) => {
                                  return (
                                    <div key={index} className={styles['info__col-info']}>
                                      {item}
                                    </div>
                                  )
                                })}
                                <div className={styles['info__col-info']}>
                                  {this.props.data[indexRow].availability.map((item, index) => {
                                    return (
                                      <div key={index} className={styles['info__col-info-date']}>
                                        {item}
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className={styles['info__buttons-wrapper']}>
                              <div
                                className={styles['info__delete-btn']}
                                onClick={() =>
                                  this.props.onEdit(
                                    row.id,
                                    this.props.fieldList,
                                    this.props.subCategories,
                                    this.props.regCategories
                                  )
                                }
                              >
                                <Icon
                                  svg={editIcon}
                                  colorStroke="light-gray"
                                  colorFill="light-gray"
                                />
                              </div>
                              <div
                                className={styles['info__delete-btn']}
                                onClick={() => this.props.onDelete(row.id)}
                              >
                                <Icon svg={deleteIcon} colorStroke="red" colorFill="red" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                })}
              </>
            ) : (
              <>
                {!this.props.isFetching ? (
                  <tr className={styles['table__body_noData']}>
                    <td colSpan={this.props.headings.length + 1}>Nothing found</td>
                  </tr>
                ) : null}
              </>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Table
