import React, { Component } from 'react'
import Modal from '../../prototypes/Modal'
import Button from '../../molecules/Button'
import { observer, inject } from 'mobx-react'
import styles from './styles.module.scss'


@inject('modalsStore')
@observer
class ConfirmModal extends Component {

  render() {
    return (
      <Modal
        open={this.props.modalsStore.showConfirmModal}
      >
        <div className={styles['modal-confirm']}>
          <h4>
            {this.props.modalsStore.confirmModalHeader}
          </h4>
          <p className={styles['modal-confirm__text']}>
            {this.props.modalsStore.confirmModalText}
          </p>
          <div className={styles['modal-confirm__buttons']}>
            <div>
              <Button
                text="No"
                type="button"
                onClick={() => this.props.modalsStore.closeConfirmModal()}
                isGray
              />
            </div>
            <div>
              <Button
                text="Yes"
                type="button"
                onClick={() => this.props.modalsStore.confirmModalSubmit()}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  }

}

export default ConfirmModal
