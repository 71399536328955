import React, { useEffect } from 'react'
import types from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.scss'

Preloader.propTypes = {
  visible: types.bool
}

export default function Preloader(props) {

  useEffect(() => {
    if (props.visible) {
      document.body.setAttribute('class', 'overflow-hidden');
    } else {
      document.body.removeAttribute('class');
    }
  }, [props.visible]);

  return (
    <div className={classNames(styles['preloader'],
      props.visible && styles['preloader-visible'])}
    >
      <div className={styles['preloader__spinner']} />
      <div className={styles['preloader__background']} />
    </div>
  )

}
