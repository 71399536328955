exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_dropdown__U1XUx {\n  width: 160px;\n  position: relative; }\n  .styles_dropdown__U1XUx label {\n    color: #A4ACB3;\n    font-size: 12px;\n    padding-bottom: 2px;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    font-weight: 600; }\n  .styles_dropdown__input__lXK47 {\n    position: relative; }\n    .styles_dropdown__input__lXK47 input {\n      width: 100%;\n      box-sizing: border-box;\n      padding: 10px 15px;\n      font-size: 14px;\n      color: #000000;\n      font-weight: 600;\n      border: 1px solid #EBEBEB;\n      border-radius: 6px;\n      cursor: pointer;\n      background: #FFFFFF;\n      text-overflow: ellipsis; }\n      .styles_dropdown__input__lXK47 input:focus {\n        outline: none; }\n  .styles_dropdown__icon__kVeBM {\n    position: absolute;\n    padding: 10px;\n    right: 0;\n    top: 50%;\n    -webkit-transform: translateY(-50%);\n            transform: translateY(-50%);\n    cursor: pointer; }\n    .styles_dropdown__icon-clear__3ZO0b {\n      position: absolute;\n      right: 0;\n      top: 25px;\n      padding: 10px;\n      cursor: pointer; }\n  .styles_dropdown__options__2POGd {\n    position: absolute; }\n", ""]);

// Exports
exports.locals = {
	"dropdown": "styles_dropdown__U1XUx",
	"dropdown__input": "styles_dropdown__input__lXK47",
	"dropdown__icon": "styles_dropdown__icon__kVeBM",
	"dropdown__icon-clear": "styles_dropdown__icon-clear__3ZO0b",
	"dropdown__options": "styles_dropdown__options__2POGd"
};