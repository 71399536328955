import { observable, action } from 'mobx'

class ModalsStore {

  @observable showConfirmModal = false
  @observable showInviteModal = false
  @observable showEditAdsModal = false
  @observable showWorksAdsModal = false
  @observable confirmModalHeader = ''
  @observable confirmModalText = ''
  @observable confirmModalCallback
  @observable editFormField = {}
  @observable editFormSubCategories = []
  @observable editFormRegCategories = []
  @observable getWorksList = () => {}


  // ConfirmModal

  @action.bound openConfirmModal(header, text, cb) {
    this.confirmModalHeader = header
    this.confirmModalText = text
    this.confirmModalCallback = cb

    this.showConfirmModal = true
  }

  @action.bound closeConfirmModal() {
    this.showConfirmModal = false
  }

  @action.bound confirmModalSubmit() {
    this.confirmModalCallback()
    this.closeConfirmModal()
  }

  // InviteModal

  @action.bound openInviteModal() {
    this.showInviteModal = true
  }

  @action.bound closeInviteModal() {
    this.showInviteModal = false
  }

  @action.bound openEditAdsModal(field,subCategories,regCategories, getWorksList) {
    this.showEditAdsModal = true
    this.showWorksAdsModal = false
    this.editFormField = field
    this.editFormSubCategories = subCategories
    this.editFormRegCategories = regCategories
    this.getWorksList = getWorksList
  }

  @action.bound openWorksAdsModal(field,subCategories,regCategories, getWorksList) {
    this.showWorksAdsModal = true
    this.showEditAdsModal = false
    this.editFormField = field
    this.editFormSubCategories = subCategories
    this.editFormRegCategories = regCategories
    this.getWorksList = getWorksList
  }

  @action.bound closeEditAdsModal() {
    this.showEditAdsModal = false
  }

  @action.bound closeWorksAdsModal() {
    this.showWorksAdsModal = false
  }

}

export default new ModalsStore()
