import axios from './axiosInstance'

export const authApi = {

  login : (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post('admin-app/login/', {
          email: email,
          password: password
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  loginConfirm : token => {
    return new Promise((resolve, reject) => {
      axios
        .post('admin-app/login/confirm/', {token: token})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  refreshToken : refresh => {
    return new Promise((resolve, reject) => {
      axios
        .post('admin-app/token/refresh/', {refresh: refresh})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  whoAmI : () => {
    return new Promise((resolve, reject) => {
      axios
        .get('users/whoami/')
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}
