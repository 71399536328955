import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Table from '../../organisms/Table'
import Pagination from '../../organisms/Pagination'
import SearchInput from '../../molecules/SearchInput'
import { observer } from 'mobx-react'
import { computed, observable } from 'mobx'
import { usersApi } from '../../../api'
import { toast } from 'react-toastify'
import { nullCheck } from '../../../helpers/utils'
import moment from 'moment'
import { addQueryParam, getQueryParam } from '../../../helpers/history'


@withRouter
@observer
class UsersRegistered extends Component {

  @observable usersData = []

  @observable ordering = ''
  @observable search = ''
  @observable limit = 10
  @observable total = 0
  @observable page = 0
  @observable isFetching = false

  constructor(props) {
    super(props)
    this.page = Number.parseInt(getQueryParam('rP', 1))
    this.limit = getQueryParam('rL', 10)
    this.ordering = getQueryParam('rO', '')
    this.search = getQueryParam('rS', '')
    this.loadData()
  }

  changeOrder = (param) => {
    this.ordering = param
    addQueryParam('rO', this.ordering)
    this.loadData()
  }

  changePage = page => {
    this.page = page
    addQueryParam('rP', page)
    this.loadData()
  }

  changeLimit = limit => {
    this.limit = limit.value
    this.page = 1
    addQueryParam('rL', limit.value)
    addQueryParam('rP', 1)
    this.loadData()
  }

  changeSearch = search => {
    this.search = search
    addQueryParam('rS', search)
    this.loadData()
  }

  loadData = () => {
    this.isFetching = true
    usersApi.getUsers(this.limit, this.offset, false, this.search, this.ordering)
      .then(response => {
        this.total = response.count
        const readyData = []
        response.results.forEach((item) => {
          const entity = {}
          entity.data = []
          entity.id = item.id
          entity.data.push(nullCheck(item.full_name))
          entity.data.push(nullCheck(item.email))
          entity.data.push(nullCheck(moment(item.registration_date).format('DD.MM.YYYY').toString()))
          entity.data.push(nullCheck(item.employer_rate))
          entity.data.push(nullCheck(item.employee_rate))
          entity.data.push(nullCheck(item.help_ads_count))
          entity.data.push(nullCheck(item.work_ads_count))
          readyData.push(entity)
        })
        if (!readyData.length) {
          if (this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.usersData = readyData
          }
        } else {
          this.usersData = readyData
        }
      })
      .catch(error => {
        toast.error('Error when trying to get registered users')
      })
      .finally(() => {
        this.isFetching = false
      })
  }

  @computed get offset() {
    return (this.page - 1) * this.limit
  }

  @computed get totalPages() {
    return Math.ceil(this.total / this.limit)
  }

  render() {
    return(
      <>
        <SearchInput
          placeholder='Search Person'
          value={this.search}
          onChange={this.changeSearch}
        />
        <Table
          headings={[{
            title: 'full name',
            filter: 'full_name'
          }, {
            title: 'email'
          }, {
            title: 'registration date',
            filter: 'registration_date'
          }, {
            title: 'employer rating',
            filter: 'employer_rate'
          }, {
            title: 'helper rating',
            filter: 'employee_rate'
          }, {
            title: 'help ads',
            filter: 'helps_count'
          }, {
            title: 'work ads',
            filter: 'works_count'
          }]}
          data={this.usersData}
          onOrderChange={this.changeOrder}
          activeOrder={this.ordering}
          isFetching={this.isFetching}
          rowRedirect='/user-details'
        />
        <Pagination
          totalPages={this.totalPages}
          currentPage={this.page}
          limit={this.limit}
          onLimitChange={this.changeLimit}
          onPagination={this.changePage}
        />
      </>
    )
  }

}

export default UsersRegistered
